import {
  Button,FormControl, Grid,MenuItem, Select, Typography, Divider, Tooltip, Card, CardHeader, CardContent, Collapse
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import BackdropLoader from '../../components/loader/BackdropLoader';
import { getRegistries, clearRegistryInfo } from '../organization/redux/actions';
import { updateClusterRepo } from './redux/actions';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import AddRegistry from '../organization/organizationinfo/registrytab/AddRegistry';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/styles";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx"
import { getDateInStandardFormat } from "../../helpers/utils";
import KeyValueRow from "../../components/keyvaluerow/KeyValueRow";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Dispatch } from "redux";
import { ClusterModel } from "../../models/Cluster.model";
import { Theme } from "@material-ui/core/styles";
import { Registry } from "../../models/Organization.model";
import { Alert } from "@material-ui/lab";
import { CustomColors } from "../../constants/enums";
// const transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme: Theme) => ({
expand: {
  transform: "rotate(0deg)",
  transition: theme?.transitions?.create("transform", {
          duration: theme?.transitions?.duration?.shortest,
      }),
},
expandOpen: {
  transform: "rotate(180deg)",
},
cardHeader:{
  padding: "0 !important"
},
  editIcon: {
      border: "2px dashed",
  },
  cardHeaderAction: {
      marginTop: 3,
      marginRight: 3
  },
  infoBackground:{
    borderRadius: '8px', 
    backgroundColor: CustomColors.Info.Lighter,
    display: 'flex',
    justifyContent : 'center'
  },
  infoText:{
      color: CustomColors.Info.Darker
  },
}));

interface Props extends PropsFromRedux {
cluster: ClusterModel;
//registrylist: Registrylist[];
clusterId: number;
mainClusterId: number;
isCreateMode?: boolean;
isInForm?: boolean;
//updatingRepoDetails: boolean;
handleRegistryChange?: (id: number) => void;
destroyed: boolean;
}

export const ClusterRegistry = (props: Props) => {
  const [registry, setRegistry] = useState(0);
  const [selectedRegistry, setSelectedRegistry] = useState<any>(null);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [editObject, setEditObject] = useState<any>(null);
  const [expanded, setExpanded] = useState(false);
  const [ t ] = useTranslation()
  const [editRegistry, setEditRegistry] = useState(false);
  
  const classes = useStyles()
  useEffect(() => {
      props.getRegistries();
      return(() => {
        props.clearRegistryInfo()
      })
  }, []);

  useEffect(() => {
      if(props.cluster?.image_registry_id > 0){
          setRegistry(props.cluster.image_registry_id)
        let _reg = props?.registrylist?.filter((x: Registry) => x.id === props.cluster.image_registry_id)[0];
          if(_reg) setSelectedRegistry(_reg);
      }else{
          setExpanded(true)
      }
  }, [props.cluster]);
  
  useEffect(() => {
      if(props.cluster?.image_registry_id > 0){
        let _reg = props?.registrylist?.filter((x: Registry) => x.id === props.cluster.image_registry_id)[0];
          if(_reg) setSelectedRegistry(_reg);
      }
  }, [props.registrylist]);

  const handleSubmit = () => {
      let jsonBody: any = new FormData();
      jsonBody.append("image_registry_id", registry);
      props.updateClusterRepo(props.clusterId, jsonBody, props.mainClusterId);
      setEditRegistry(false)
  };

  const handleRegistryChange = (e: React.ChangeEvent<{ value: unknown }>) => {
      setRegistry(e.target.value as number);
      if (props.isCreateMode && props.handleRegistryChange) {
          props.handleRegistryChange(e.target.value as number);
      }
  }

  const isFormValid = () => {
      let valid = false;
      if (registry > 0 && registry !== props?.cluster?.image_registry_id) {
          valid = true;
      }
      return !valid;
  }

  const handleAddRegistry = () => {
      setOpenAddPopup(true);
  }

  const handleCancelPopUp = () => {
      setOpenAddPopup(false);
      setEditObject(null);
  }

  const addSuccessCallback = (data: any) => {
      props.getRegistries();
      setRegistry(data.id)
      if (props.isCreateMode && props.handleRegistryChange) {
          props.handleRegistryChange(data.id);
      }
      handleCancelPopUp();
  }

  const handleEditIconClick = () => {
      setEditRegistry(!editRegistry);
  }

  useEffect(() => {
      setRegistry(props?.cluster?.image_registry_id ?? 0)
  }, [editRegistry])

  const content = (
    <Grid container spacing={2} alignItems="flex-end" data-test="content-container">
      <Grid item xs={12} md={props.isCreateMode ? 12 : 12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="flex-start"
            >
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={12} md={8}>
                    <FormControl
                      // error={""}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    >
                      <Select
                        //error={this.state.isRepoTypeDropDownFeildError}
                        //helperText={this.state.repoTypeDropDownErrorMessage}
                        //className={classes.selectRegion}
                        className="w-100"
                        color="primary"
                        data-test="registry-select"
                        //labelId="label-registry"
                        //id="simple-select"
                        value={registry}
                        //name='repositoryType'
                        //label='Select Registry'
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleRegistryChange(e)}
                        MenuProps={{
                          disableScrollLock: true,
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        <MenuItem value={0}>
                          {t("Cluster.ClusterRegistry.selectRegistry")}
                        </MenuItem>
                        {props.registrylist &&
                          props.registrylist.length > 0 &&
                          props.registrylist.map((_registry: Registry, index: number) => (
                            <MenuItem value={_registry.id} key={index}>
                              {_registry.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {props.isInForm ? (
                    <Grid item xs={6} md={2} >
                      <Tooltip arrow title={t("Cluster.ClusterRegistry.addNewRegistry")}>
                        <IconButton data-test="add-new-registryicon" onClick={() => handleAddRegistry()}>
                          <AddCircleOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : (
                    <Grid item xs={6} md={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleAddRegistry()}
                        disabled={props.destroyed}
                        data-test="add-new-registrybutton"
                        className="w-100"
                      >
                        {t("Cluster.ClusterRegistry.addNew")}
                      </Button>
                    </Grid>
                  )}
                  {!props.isCreateMode && (
                    <Grid item xs={6} md={2}>
                      <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        disabled={isFormValid() || props.destroyed}
                        size="large"
                        data-test="update-button"
                        className="w-100"
                      >
                        {t("Cluster.ClusterRegistry.update")}
                      </Button>
                    </Grid>
                  )}
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
  
  return (
      !props.isInForm ?
      <Card className="m-t-20" data-test="card-container" elevation={expanded ? 11 : 13}>
          <CardHeader 
              data-test="card-header"
              // className={classes.cardHeader}
              // classes = {{action: classes.cardHeaderAction}}
              title={
                  <Typography variant="subtitle1" display="inline" data-test="dialog-title">
                      {t('Cluster.ClusterRegistry.registry')}
                      {props?.cluster?.image_registry_id > 0  && (
                          <IconButton disabled style={{padding : "5px"}} data-test="checked-icon">
                              <CheckCircleSharpIcon
                              fontSize="small"
                              style={{ color: "green" }}
                              />
                          </IconButton>
                        )}
                  </Typography>
              }
              subheader ={
                  <Typography display="block" className="m-b-5" variant="body2" data-test="dialog-subheader">{t('Cluster.ClusterRegistry.registryRequired')}</Typography>
              }
              // avatar={
              //     <IconButton
              //       className={clsx(classes.expand, {
              //         [classes.expandOpen]: expanded,
              //       })}
              //       onClick={() => {
              //         setExpanded(!expanded);
              //         setEditRegistry(false);
              //       }}
              //       data-test="icon-button"
              //     >
              //       <ExpandMoreIcon />
              //     </IconButton>
              // }
              action={
                <>
                  {!props.destroyed && expanded && (
                      <Tooltip
                          title={editRegistry ? t('Cluster.ClusterRegistry.cancelEdit') : t('Cluster.ClusterRegistry.editRegistry')}
                          placement="left"
                          // className={classes.storageEditIcon}
                      >
                          <IconButton
                            onClick={() => handleEditIconClick()}
                            className={editRegistry ? classes.editIcon : ""}
                            data-test="edit-button"
                          >
                              <EditIcon />
                          </IconButton>
                      </Tooltip>
                  )}
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={() => {
                      setExpanded(!expanded);
                      setEditRegistry(false);
                    }}
                    data-test="icon-button"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </>
              }
          />

          <Collapse in={expanded}>
          {!editRegistry ? (
              <CardContent data-test="registryinfo-container">
                  {selectedRegistry ? (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                            <KeyValueRow keyXs={3} rowKey={"Name"} rowValue={selectedRegistry?.name  ?? ""} data-test="name-info" />
                            <KeyValueRow keyXs={3} rowKey={"Created"} rowValue={selectedRegistry.createdat ? getDateInStandardFormat(selectedRegistry.createdat) : ""} data-test="created-info" />
                            <KeyValueRow keyXs={3} rowKey={"Provider"} rowValue={selectedRegistry?.provider  ?? ""} data-test="provider-info" />
                            <KeyValueRow keyXs={3} rowKey={"Active"} rowValue={selectedRegistry?.active.toString()  ?? ""} data-test="active-info" />
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid>
                      <div className="cluster-info-message">
                        {/* <ErrorOutlineIcon fontSize="small" />
                        <Typography
                          variant="caption"
                          className="message"
                          data-test="no-registry-text"
                        >
                          {t("Cluster.ClusterRegistry.noRegistry")}
                        </Typography> */}
                        <Alert severity="info" className={`${classes.infoBackground} w-100`}>
                            <Typography variant='body2' align="center" className={classes.infoText}>{t("Cluster.ClusterRegistry.noRegistry")}</Typography>
                        </Alert>
                      </div>
                    </Grid>
                  )}
              </CardContent>
              ) : (
              <CardContent data-test="edit-content">
                  {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                      onClick={() => handleEditIconClick()}
                      variant="contained"
                      color="primary"
                    >
                      {t('Cluster.ClusterRegistry.cancelEdit')}
                    </Button>
                  </div>
                  <br /> */}
                  {content}
                  { props.updatingRepoDetails && <BackdropLoader message={t('Cluster.ClusterRegistry.updatingRegistry')}/>}
                  {
                      openAddPopup &&
                      <Dialog
                          open={openAddPopup}
                          keepMounted
                          onClose={handleCancelPopUp}
                          aria-labelledby="alert-dialog-slide-title"
                          aria-describedby="alert-dialog-slide-description"
                          disableBackdropClick={true}
                          data-test="add-dialog"
                      >
                          <DialogTitle id="alert-dialog-slide-title">
                              <Typography className='dialogtitle'>{ editObject ?  t('Cluster.ClusterRegistry.editRegistry') : t('Cluster.ClusterRegistry.addRegistry') }</Typography>
                              <IconButton aria-label="close" size="small" className='right' onClick={handleCancelPopUp}>
                                  <CloseIcon />
                              </IconButton>
                          </DialogTitle>
                          <AddRegistry successCallback={addSuccessCallback} registryDetails={editObject} data-test="add-registry"/>
                      </Dialog>
                  }
              </CardContent>
          )}
          </Collapse>

      </Card>
      :
      (
          <span data-test="form-container">
              {content}

              { props.updatingRepoDetails && <BackdropLoader message={t('Cluster.ClusterRegistry.updatingRegistry')}/>}

              {
                  openAddPopup &&
                  <Dialog
                      open={openAddPopup}
                      keepMounted
                      onClose={handleCancelPopUp}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      disableBackdropClick={true}
                  >
                      <DialogTitle id="alert-dialog-slide-title">
                          <Typography className='dialogtitle'>{ editObject ?  t('Cluster.ClusterRegistry.editRegistry') : t('Cluster.ClusterRegistry.addRegistry') }</Typography>
                          <IconButton aria-label="close" size="small" className='right' onClick={handleCancelPopUp}>
                              <CloseIcon />
                          </IconButton>
                      </DialogTitle>
                      <Divider />
                      {/* <DialogContent dividers>
                          <DialogContentText id="alert-dialog-slide-description"> */}
                              <AddRegistry successCallback={addSuccessCallback} registryDetails={editObject}/>
                          {/* </DialogContentText>
                      </DialogContent> */}
                  </Dialog>
              }
          </span>
      )
  );
};

const mapStateToProps = (state: any) => ({
  updatingRepoDetails: state.ClusterReducer.updatingRepoDetails,
  registrylist: state.OrganizationReducer.registrylist
})

const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
      updateClusterRepo: (id: number, jsonBody: any, mainClusterId: number) => dispatch(updateClusterRepo(id, jsonBody, mainClusterId)),
      getRegistries: () => dispatch(getRegistries()),
      clearRegistryInfo: () => dispatch(clearRegistryInfo()),
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ClusterRegistry);