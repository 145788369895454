import { ButtonBase, Divider, Grid, MenuItem, MenuList, Paper, Popover, Typography, createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import BackdropLoader from '../../../components/loader/BackdropLoader';
import { currentOrganization } from '../../login/redux/selectors';
import { switchOrg } from '../redux/actions';
import { Theme } from "@material-ui/core";
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { OrganizationModel } from '../../../models/Organization.model';
import { History } from 'history';
import AddIcon from '@material-ui/icons/Add';
import { ZeroOneCloudIcon } from '../../../helpers/customIcon';
import { CustomColors } from '../../../constants/enums';
import palette from '../../../theme/palette';
import { StyledDivider } from '../../../components/dashedDivider/DashedDivider';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

/* istanbul ignore next */
const useStyles = (theme: Theme) => createStyles({
  icon: {
    marginRight: 3,
    fontSize:'1rem',
    verticalAlign:'middle'
  },
  input: {
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  popover: {
    padding: theme.spacing(1),
    background: "rgba(128,128,128,0.5)"
  },
  searchImg:{
    width:170,
    objectFit:'cover',
    padding:20,
  },
  resultList:{
    minWidth:300,
    marginTop:10,
    '&:hover': {
      backgroundColor: 'transparent', // or any color you prefer
    },
  },
  link:{
    textDecoration:'none',
  },
  paperRoot:{
    width:'100%'
  },
  rowContainer: {
    display: 'flex',
    padding: '0.5rem',
    alignItems: 'center',
    justifyContent: "space-between"
  },
  title: {
    color: "#0057fa",
    marginLeft: "1rem",
    fontWeight : 500
  },
  subTitle: {
    color: "#43425D",
    marginLeft: "1rem",
    fontWeight : 200,
    fontSize:12
  },
  smallTitle: {
    color : "#0057fa",
    fontSize: "1rem"
  },
  titleShorter: {
    width: 24,
    height: 24,
    background: 'white',
    border: "1px solid #0057fa",
    borderRadius: '50%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0 
  },
  vertMiddle:{
    verticalAlign:'middle'
  },
  ownerInfoContainer:{
    display: 'flex', 
    alignItems: 'center', 
    gap: 6, 
    backgroundColor: CustomColors.Info.Lighter,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 700,
    color: CustomColors.Info.Dark,
    borderRadius: 6,
    padding: '2px 6px',
    
  },
  orgDetailscontainer:{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    width: '100%',
    gap: 20
  },
  menuitem: {
    minWidth: 390, 
    padding: 8,
    '&:hover $ownerInfoContainer' : {
      backgroundColor: palette.background.neutral,
      color: palette.text.secondary,
    },
    [theme.breakpoints.down('sm')]: { 
      minWidth: '100%', 
    },
  }
});

interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles> {
  history: History
}

interface State {
  // value: string;
  anchorEl: Element | ((element: Element) => Element) | null;
  //tempAnchorEl: Element | ((element: Element) => Element) | null;
}

export class OrganizationSwitch extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
      // value: '',
      anchorEl: null,
      //tempAnchorEl : null,
      //selectedOrgName: '01Cloud'
    }
  }

  handleExpandClick = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ 
        anchorEl : e.currentTarget 
    });
  }

  handleClose = () => {
    this.setState({ anchorEl : null });
  };

//   handleResultClick = (id) => {
//     this.clearResult();
//   }

  handleItemClick = (item: OrganizationModel | Pick<OrganizationModel, "id">) => {
    this.props.switchOrg(item.id, this.props.history)
    // this.setState({
    //     selectedOrgName: item.name
    // })
    this.handleClose();
    //this.props.onSelectedOrg(item)
  }
  isCreateOrgDisabled=()=>{
    const {organizationList,currentUser}=this.props;
  
    if(organizationList===null ||!organizationList.length|| !currentUser){
      return false
    }
    
    const orgCount=organizationList.filter((project: OrganizationModel)=>project.user_id===currentUser.id).length
    
      return orgCount>= currentUser.quotas.user_organization;
    }
  render () {
    const { classes, userDetails, t } = this.props;
    return (
        <div data-test="main-container">
      
            {/* <SearchIcon className={classes.icon} />
            <Input
              className={classes.input}
              disableUnderline
              onChange={ this.handleOnChange }
              value={ this.state.value }
              placeholder={t("SearchProjectsApps")}
              //onFocus={ this.handleSearchFocus}
              aria-describedby='simple-popper'
            /> */}
            <ButtonBase onClick={ (e) => this.handleExpandClick(e) } data-test="switch-btn">
                <Typography variant="h6" color={'textPrimary'}  className="top-org" data-test="current-org">
                  { this.props.currentOrganization && this.props.currentOrganization.id > 0 ? this.props.currentOrganization.name : 
                  ( userDetails ? userDetails.first_name + ' ' + userDetails.last_name : '01Cloud' )  } <ExpandMoreIcon className={classes.vertMiddle}/>
                </Typography>
            </ButtonBase>
            <Popover
              id='simple-popper' 
              open={Boolean(this.state.anchorEl)} 
              anchorEl={this.state.anchorEl} 
              className={classes.popover} 
              onClose={this.handleClose}
              disableAutoFocus
              disableEnforceFocus
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              data-test="popover"
            >
                <MenuList className={`${classes.resultList} orgswitch`}>
                    {
                      (!this.props.organizationList) &&
                        [0,1].map((item) => (
                            <MenuItem key={item} data-test="skel">
                                {/* <Paper elevation={1} className={classes.paperRoot}> */}
                                    <div className={ classes.rowContainer }>
                                        <Skeleton circle={true} height={40} width={40} />
                                        <div className='projectTitlesContainer'>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Skeleton height={10} width="40%" />
                                                </Grid>
                                                <Grid item>
                                                    <Skeleton height={10} width="20%" />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                {/* </Paper> */}
                            </MenuItem>
                        ))
                    }
                    <div style={{padding: '16px 24px'}}>
                      <Typography variant='subtitle2' color='textSecondary'>Organization</Typography>
                    </div>
                    {
                this.props.organizationList && this.props.organizationList.length > 0 &&  
                this.props.organizationList.map((item: OrganizationModel, ind: number) => {
                    return (
                      <>
                        <MenuItem key={ind} data-test="org-item" className={classes.menuitem}>
                            {/* <Paper elevation={1} style={{width: '100%', height: '100%'}}> */}
                            <div onClick={() => this.handleItemClick(item)} data-test="item-div" className='w-100'>
                              <div className={classes.orgDetailscontainer}>
                                <div style={{ display: 'flex', gap: 12, alignItems: 'center', flex: 1 }}>
                                  <div>
                                    {
                                      item.image && 
                                      <div style={{ overflow: 'hidden', height: 24, width: 24, borderRadius: '50%' }}>
                                        <img data-test="org-image" src={ item.image } alt='' height={24} width={24} />
                                      </div>
                                    }
                                    { !item.image &&
                                      <div className={ classes.titleShorter } data-test="org-avatar">
                                        <p className={ classes.smallTitle }>{ item.name !== "" && item.name.charAt(0).toUpperCase() }</p>  
                                      </div>
                                    }
                                  </div>
                                  <div>
                                    <Typography variant='subtitle2' data-test="org-name">{ item.name }</Typography>
                                  </div>
                                </div>
                                {item && item.user && item.user.first_name && item.user.last_name &&
                                  <p className={classes.ownerInfoContainer}>
                                    {/* <ZeroOneCloudIcon viewBox='0 0 16 16'/> */}
                                    <PersonOutlineOutlinedIcon fontSize='small'/>
                                    {`${item.user.first_name} ${item.user.last_name} ${this.props.currentUser && item.user.id === this.props.currentUser.id ? "( me )" : ""}` }
                                  </p>
                                }
                              </div>
                            </div>
                            {/* </Paper> */}
                        </MenuItem>
                        <StyledDivider />
                      </>
                    )
                })
            }
                    {
              this.props.organizationList && this.props.organizationList.length > 0 && 
              <>
                <MenuItem className={classes.menuitem} data-test="default-org">
                    {/* <Paper elevation={1} className={classes.paperRoot}> */}
                        <div className='w-100' onClick={() => this.handleItemClick({ id:0 }) }>
                            <div className={classes.orgDetailscontainer}>
                                  <div style={{ display: 'flex', gap: 12, alignItems: 'center', flex: 1 }}>
                                    <div>
                                        <div style={{ overflow: 'hidden', height: 24, width: 24, borderRadius: '50%' }}>
                                          <img src="/images/logos/logo-blue.svg" alt='' height={24} width={24} data-test="default-org-image" />
                                        </div>
                                    </div>
                                    <div>
                                      <Typography variant='subtitle2' data-test="org-name">{ userDetails ? userDetails.first_name + ' ' + userDetails.last_name : '01Cloud' }</Typography>
                                    </div>
                                  </div>
                                    <p className={classes.ownerInfoContainer}>
                                      {/* <ZeroOneCloudIcon viewBox='0 0 16 16'/> */}
                                      Default Organization
                                    </p>
                                </div>
                        </div>
                    {/* </Paper> */}
                </MenuItem>
                {!this.isCreateOrgDisabled() && <StyledDivider />}
              </> 
            } {
              !this.isCreateOrgDisabled() &&
                <MenuItem style={{padding: '24px 24px'}}>
                      <Link data-test="create-org-link" to='/organization/create' onClick={() => this.handleClose() }  style={{textDecoration: 'none', display: 'flex', alignItems: 'center', gap: 16}}>
                        <AddIcon fontSize='medium' color='primary'/> 
                        <Typography variant='h6'>{t('Organization.CreateOrganization.createOrg')}</Typography>
                      </Link>
                </MenuItem>
            }
                   
                </MenuList>
            </Popover>
            {this.props.switchingOrg && <BackdropLoader message={t('Organization.CreateOrganization.switchOrg')} /> }
        </div>
    )
  }
}

/*istanbul ignore next */
const mapStateToProps = (state: any) => ({
  userDetails : state.AuthReducer.user,
  organizationList: state.OrganizationReducer.organizationList,
  switchingOrg: state.OrganizationReducer.switchingOrg,
  currentOrganization: currentOrganization(state),
  currentUser: state.AuthReducer.user,
})

/*istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    switchOrg: (id: number, history: History) => dispatch(switchOrg(id, history))
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(OrganizationSwitch)));
