import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  IconButton,TextField,
  makeStyles,
  Collapse,
  Divider
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import { isAuthorized } from "../../helpers/utils";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from 'react-i18next';
import { ScriptsModel, UserRole } from "../../models/Environment.model";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

/* istanbul ignore next */
const useStyles = makeStyles(() => ({
  expand: {
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const aceStyles = {
  mode: "yaml",
  theme: "monokai",
  fontSize: 14,
  showPrintMargin: false,
  width: "100%",
  height: "50vh",
  name: "command",
  className: "m-t-10",
};


type Props = {
  source: number,
  scripts?: ScriptsModel,
  onChange:(_scripts: ScriptsModel) => void,
  handleSubmit?: () => void,
  envRole?: UserRole,
  readOnly?: boolean
}

export default function Scripts(props: Props) {
  //const [runScript, setRunScript] = useState("");
  // const [buildScript, setBuildScript] = useState("");
  const [dockerFile, setDockerFile] = useState("");
  const [subDirPath,setSubDirPath]=useState("")
  const [edit, setEdit] = useState(false);
  const [expandScript,setExpandScript] = useState(false);
  const [t] = useTranslation()
  const classes = useStyles();


  useEffect(() => {
    if (props.source === 1 || edit) {
      const scripts: ScriptsModel = {
        //run: runScript,
        sub_dir:subDirPath,
        dockerfile: dockerFile,
        //build: build,
        ci_steps: props.scripts?.ci_steps ? props.scripts.ci_steps : []
      };

      props.onChange(scripts);
    }
  }, [dockerFile,subDirPath]); //runScript, buildScript


  useEffect(() => {
    if (props.scripts) {
      setDockerFile(props.scripts?.dockerfile);
      //setRunScript(props.scripts?.run);
      setSubDirPath(props.scripts?.sub_dir);
    }
  }, [props.scripts]);

  useEffect(() => {
    return () => {
      setEdit(false);
      setDockerFile("");
      //setRunScript("");
      setSubDirPath("")
    };
  }, []);

  const dockerScriptChange = (value: string) => {
    setDockerFile(value?.trim());
  };

  // const runScriptChange = (value: string) => {
  //   setRunScript(value?.trim());
  // };

  const editHandler = () => {
    setEdit(true);
  };

  const handleSubmit = () => {
    if(props.handleSubmit){
      props.handleSubmit();
    }
  };

  const handleCancel = () => {
    setEdit(false);
    setDockerFile(props.scripts?.dockerfile ?? "");
    //setRunScript(props.scripts?.run ?? "");
    setSubDirPath(props.scripts?.sub_dir??"")
  };

  const hasChanged = () => {
    //  Initially, if we do not get any scripts , we need to check if the user has entered any scripts or not.
    if (
      !props.scripts &&
      !dockerFile?.trim().length &&
      // !runScript?.trim().length &&
      !subDirPath?.trim().length
    ) {
      return false;
    }
    if (
      dockerFile !== props?.scripts?.dockerfile ||
      subDirPath !== props?.scripts?.sub_dir 
      //runScript !== props.scripts?.run
    ) {
      return true;
    }
    return false;
  };

  const handlePath=(e:React.ChangeEvent<{ name?: string; value: unknown }>)=>{
    setSubDirPath(e.target.value as string);
  }

  const handleExpandScript = () => {
    setExpandScript(prevState => !prevState)
  }

  return (
    <div data-test="scriptsContainer">
      <Card className={props.source === 2 ? "scripts" : ""}>
        <CardHeader
          title={<Typography variant="h6">{props.source === 2 ? "Dockerfile" : "Sub Directory Path"}</Typography>}
          action={
            props.source === 2 ? (
              isAuthorized("update", props.envRole?.name) ? (
                <Grid container spacing={2} data-test="actionsContainer">
                  {edit && (
                    <Grid item>
                      <Button
                        onClick={handleCancel}
                        variant="contained"
                        color="primary"
                        data-test="cancelButton"
                      >
                        {t("Cancel")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    {edit ? (
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!hasChanged()}
                        data-test="updateButton"
                      >
                        {t("Update")}
                      </Button>
                    ) : (
                      <IconButton
                        data-test="editButton"
                        title="Edit Scripts"
                        onClick={() => editHandler()}
                      >
                        <EditIcon
                          color="primary"
                          // variant="contained"
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ) : (
                ""
              )
            ) : (
              ""
            )
          }
          avatar={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandScript,
              })}
              onClick={handleExpandScript}
            >
              <ExpandMoreIcon color="primary"/>
            </IconButton>
          }
        />
        <Collapse in={expandScript}>
          <Divider/>
          <CardContent>
            <Grid container spacing={2} direction="column">

              {props.source === 2 && 
              // <Grid item xs={12} data-test="dockerfileContainer" style={{marginTop:"-23px"}}>
              <Grid item xs={12} data-test="dockerfileContainer">
                {/* <Typography variant="h5">Dockerfile</Typography> */}
                <AceEditor
                  placeholder={t("BuildScriptPlaceHolder")}
                  {...aceStyles}
                  onChange={(val) => {
                    dockerScriptChange(val);
                  }}
                  readOnly={!edit && props.readOnly}
                  value={dockerFile ?? ""}
                  setOptions={{
                    showLineNumbers: true,
                    tabSize: 4,
                  }}
                  minLines={10}
                  style={{padding: '24px 0px', borderRadius: 16}}
                />
              </Grid>
              }
              <Grid item xs={4} data-test="subDirectoryContnainer" style={{ marginTop: props.source === 2 ?"30px":""}}>
              {props.source === 2 && <Typography variant="h5">Sub Directory Path</Typography>}
                <TextField
                  variant="outlined"
                  // label="Path"

                  disabled={!edit && props.readOnly}
                  margin="normal"
                  name="path"
                  value={subDirPath}
                  data-test="path-field"
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                    handlePath(e)
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePath(e)
                  }
                  focused={expandScript}
                  className="w-100"
                  placeholder={"/"}
                />
              </Grid>
              {/* <Grid item xs={12} data-test="runScriptContainer">
                <Typography variant="h5">{t("RunScript")}</Typography>

                <AceEditor
                  placeholder={t("RunScriptPlaceHolder")}
                  {...aceStyles}
                  onChange={(val) => {
                    runScriptChange(val);
                  }}
                  readOnly={!edit && props.readOnly}
                  value={runScript ?? ""}
                  setOptions={{
                    showLineNumbers: true,
                    tabSize: 4,
                  }}
                  style={
                    !edit && props.readOnly
                      ? { pointerEvents: "none", opacity: 0.8 }
                      : { opacity: 1 }
                  }
                  minLines={10}
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
