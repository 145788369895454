import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography, createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import AuthorizeContainerPopUp from '../../../components/authorizecontainerpopup/AuthorizeContainerPopUp';
import ConfirmActionPopup from '../../../components/confirmactionpopup/ConfirmActionPopup';
import BackdropLoader from '../../../components/loader/BackdropLoader';
// import Toast from '../../../components/toast/Toast';
import { AppConstants } from "../../../constants/appconstants";
import { crConnect, gitConnect } from '../../app/redux/actions';
import { clearAccountList, getAccountsList, unlinkAccount } from './redux/actions';
// import { CreateApp_TemplateTypes } from '../../../constants/enums'
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';
import { AuthProvider, LinkedAccountModel } from '../../../models/Account.model'

/* istanbul ignore next */
const useStyles = () => createStyles({
    imgIcon: {
        paddingRight: 4,
    },
    typographyExtend: {
        padding: '8px !important'
    },
    borderStyle: {
        border: '1px solid lightgray',
        padding: 4,
        paddingLeft: 10
    },
    box: {
        marginTop: 7,
        paddingRight: 4,
        display: 'flex',
        alignItems: 'center',
    },
    buttonStyle: {
        width: "140px !important",
        fontSize: '11px !important', 
        padding: '3px 16px !important',
        borderRadius: 8
    }
});

interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles> {}

type State = {
    selectedProvider: any,
    isOpenAuthPopup: boolean,
    isConfirmPopuup: boolean,
    idToBeDeleted: number
}

interface AccountItemModel extends WithStyles<typeof useStyles> {
    index: number,
    accountList: LinkedAccountModel[],
    provider: AuthProvider,
    handleServiceSelection: (provider: AuthProvider) => void,
    handleUnlink: (id: number) => void,
}

export const LinkedAccountItem = (props: AccountItemModel) => {
    // const { provider, classes, t } = props;
    const { provider, classes } = props;
    const [t] = useTranslation()

    const isLinked = provider?.service_name && props.accountList ? props.accountList?.find((acc: LinkedAccountModel) => provider.service_name === acc.service_name) : null;
    // const isLinked = (serviceName) => {
    //     return props.accountList?.find((acc) => serviceName === acc.service_name)
    // }

    return (
        <Card elevation={14} className={`${props.index > 0 ? 'm-t-20' : ''} link-card`}>
            <CardContent>
                <Grid container data-test="providers"  >
                    <Grid item md={4} xs={4} sm={4}>
                        <div className={classes.box}>
                            <img src={provider.icon} alt={provider.name} title={provider.name} className={classes.imgIcon} data-test="image" />
                            <Typography data-test="name" variant='body2'> {provider.name} </Typography>
                        </div>
                    </Grid>

                    {
                        isLinked ?
                            <>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.typographyExtend} variant='body2' data-test="username">
                                        {isLinked?.service_user_name ?? "-"}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Button onClick={() => props.handleUnlink(isLinked?.id)} className={classes.buttonStyle} variant='contained' color='secondary' data-test="unlinkButton">
                                        {t('Account.ProfileTab.unlinkAccount')}
                                    </Button>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.typographyExtend} variant='body2' data-test="emptyUsername">
                                        {/* {isLinked?.service_user_name ?? "-"} */}
                                        {"-"}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Button onClick={() => props.handleServiceSelection(provider)} className={classes.buttonStyle} variant='contained' color='primary' data-test="linkButton">
                                        {t('Account.ProfileTab.linkAccount')}
                                    </Button>
                                </Grid>
                            </>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export class LinkedAccounts extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedProvider: null,
            isOpenAuthPopup: false,
            isConfirmPopuup: false,
            idToBeDeleted: 0
        }
    }

    componentDidMount() {
        this.props.getAccountsList()
        const $this = this;
        window.addEventListener('message', function (e) {
            if (e?.data?.source?.match('react-devtools')) return;
            $this.props.getAccountsList();
        }, false)
    }

    componentWillUnmount() {
        this.props.clearAccountList()
        window.removeEventListener('message', function () { })
    }

    openOAuthClient = () => {
        const { selectedProvider } = this.state;
        if (selectedProvider.authType === 1) {
            setTimeout(() => window.open(`${selectedProvider.authorizeEndpoint}?${selectedProvider.params}`, "01Cloud - OAuth", "status=no,location=no,toolbar=no,menubar=no,width:200,height=100"), 100);
        }
        else if (selectedProvider.authType === 2 || selectedProvider.authType === 3) {
            this.setState({
                isOpenAuthPopup: true
            })
        }
    }

    handleServiceSelection = (_provider: AuthProvider) => {
        console.log(_provider, "info")
        this.setState({
            selectedProvider: _provider
        }, () => this.openOAuthClient())
    }

    authTokenTypeCallBack = () => {
        this.setState({
            isOpenAuthPopup: false
        })
        this.props.getAccountsList()
    }

    handleCancelPopUp = () => {
        this.setState({
            isOpenAuthPopup: false
        })
    }

    handleUnlink = (id: number) => {
        this.setState({
            isConfirmPopuup: true,
            idToBeDeleted: id
        })
    }

    handleUnlinkAgree = () => {
        this.props.unlinkAccount(this.state.idToBeDeleted)
        this.handleUnlinkDisagree()
    }

    handleUnlinkDisagree = () => {
        this.setState({
            isConfirmPopuup: false,
            idToBeDeleted: 0
        })
    }

    render() {
        const { selectedProvider } = this.state;
        const { t, classes } = this.props;
        return (
            <div data-test="main-container">
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12} >
                        <Box className="alertwhitecontainer" data-test="linked-account-card" >
                            {/* <CardHeader title={t('Account.ProfileTab.linkedAccounts')} />
                            <Divider /> */}
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        {
                                            AppConstants?.gitProviders && AppConstants?.gitProviders?.length > 0 && AppConstants?.gitProviders?.map((_provider, ind) =>
                                                <LinkedAccountItem
                                                    key={ind}
                                                    index={ind}
                                                    provider={_provider}
                                                    classes={classes}
                                                    // t={t}
                                                    accountList={this.props.accountList}
                                                    handleServiceSelection={this.handleServiceSelection}
                                                    handleUnlink={this.handleUnlink}
                                                    data-test="git-providers"
                                                />
                                            )
                                        }
                                    </Grid>

                                    {/* design */}

                                    <Grid item md={12} sm={12} xs={12}>
                                        {
                                            AppConstants?.crProviders && AppConstants?.crProviders?.length > 0 && AppConstants?.crProviders?.map((_provider, ind) =>
                                                <LinkedAccountItem
                                                    key={ind}
                                                    index={ind}
                                                    provider={_provider}
                                                    classes={classes}
                                                    // t={t}
                                                    accountList={this.props.accountList}
                                                    handleServiceSelection={this.handleServiceSelection}
                                                    handleUnlink={this.handleUnlink}
                                                    data-test="cr-providers"
                                                />
                                            )
                                        }

                                        {/* design end */}
                                    </Grid>
                                </Grid>
                        </Box>
                    </Grid>
                </Grid>
                {
                    this.state.isOpenAuthPopup &&
                    <AuthorizeContainerPopUp
                        data-test="authorizeContainerPopup"
                        authTokenTypeCallBack={() => this.authTokenTypeCallBack()}
                        //variables={this.state.variables}
                        //isErrors={this.state.isErrors}
                        //handleValueChange={this.handleValueChange}
                        isOpenAuthPopup={this.state.isOpenAuthPopup}
                        handleCancelPopUp={() => this.handleCancelPopUp()}
                        selectedProvider={selectedProvider}
                    />
                }
                <ConfirmActionPopup
                    data-test="action-popup-component"
                    open={this.state.isConfirmPopuup}
                    message={t('Account.ProfileTab.unlinkMessage')}
                    handleAgree={() => this.handleUnlinkAgree()}
                    handleDisAgree={() => this.handleUnlinkDisagree()}
                    yesText={t('Account.ProfileTab.unlink')}
                />
                {this.props.loadingAccountList && <BackdropLoader data-test="loadingList" message={t('Account.ProfileTab.loadingList')} />}
                {this.props.unlinkingAccount && <BackdropLoader data-test="unlinkingAccount" message={t('Account.ProfileTab.unlinkingAccount')} />}
                {this.props.connecting && <BackdropLoader data-test="linkingAccount" message={t('Account.ProfileTab.linkingAccount')} />}
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
    connecting: state.AppsReducer.connecting,
    accountList: state.ProfileTabReducer.accountList,
    loadingAccountList: state.ProfileTabReducer.loadingAccountList,
    unlinkingAccount: state.ProfileTabReducer.unlinkingAccount
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        crConnect: (jsonBody: any, callback:(data: string)=>void, window: Window) => dispatch(crConnect(jsonBody, callback, window)),
        gitConnect: (jsonBody: any, window: Window, callback:(data: string)=>void) => dispatch(gitConnect(jsonBody, window, callback)),
        getAccountsList: () => dispatch(getAccountsList()),
        unlinkAccount: (id: number) => dispatch(unlinkAccount(id)),
        clearAccountList: () => dispatch(clearAccountList())
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(LinkedAccounts)));