import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    FormControlLabel,
    Grid,
    Switch,
    MenuItem,
    Typography,
    IconButton,
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
    Collapse,
    Box,
    Paper,
} from "@material-ui/core";
import { Input as AntdInput } from 'antd';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import CronJobSkeleton from '../../../../../components/skeletons/CronJobSkeleton'
import React, { useEffect } from "react";
import Cron, { CronError } from 'react-js-cron';
import cronstrue from "cronstrue";
import { isValidCron } from 'cron-validator'
import { connect,ConnectedProps } from "react-redux";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import { isAuthorized, getDateInStandardFormat } from '../../../../../helpers/utils';
import { updateEnvSchedule, fetchScheduleLog } from "../../../redux/actions";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { AppConstants } from "../../../../../constants/appconstants"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FilledStatusIndicator } from '../../../../../components/statusindicator/statusindicator'
import RefreshIcon from '@material-ui/icons/Refresh';
import Alert from '@material-ui/lab/Alert';
import { Dispatch } from "redux";
import { SchedulesModel } from "../../../../../models/Environment.model";
import { CustomColors } from "../../../../../constants/enums";

//import TimezoneSelect from 'react-timezone-select'

/* istanbul ignore next */
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    title: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    infoBackground:{
        borderRadius: '8px', 
        backgroundColor: CustomColors.Info.Lighter
    },
    infoText:{
        color: CustomColors.Info.Darker
    },
    infoPaper: {
        margin: "20px 20px",
    },
}));

interface IscheduleFrequencyProps{
frequency:number ;
title:string; subTitle:string;
source:number;
updateExpression:(source:number,value:string)=>void;
initialExpression:string;
}
export function ScheduleFrequency(props:IscheduleFrequencyProps) {
    const { frequency } = props;
    //const [scheduleTime, setScheduleTime] = React.useState(0);
    const [scheduleHour, setScheduleHour] = React.useState(0);
    const [scheduleMinute, setScheduleMinute] = React.useState(1);
    const [scheduleDate, setScheduleDate] = React.useState(1);
    const [scheduleDay, setScheduleDay] = React.useState(0);
    //const [changed, setChanged] = React.useState(false);
    // const [scheduleType, setScheduleType] = React.useState();
    const [advanceExpression, setAdvanceExpression] = React.useState<string>("");
    //const [cronError, setCronError] = React.useState(false);
    const [cronErrorMessage, setCronErrorMessage] = React.useState('');
    // const cronExpressionRef = React.createRef(null);
    const [t] = useTranslation()
    const classes = useStyles();

    const handleScheduleHourChange = (event:React.ChangeEvent<{ value: unknown }>) => {
        //setChanged(true);
        setScheduleHour(event.target.value as number);
    }
    const handleScheduleMinuteChange = (event:React.ChangeEvent<{ value: unknown }>) => {
        //setChanged(true);
        setScheduleMinute(event.target.value as number);
    }

    const handleScheduleDateChange = (event:React.ChangeEvent<{ value: unknown }>) => {
        //setChanged(true);
        setScheduleDate(event.target.value as number);
    };
    const handleScheduleDayChange = (event:React.ChangeEvent<{ value: unknown }>) => {
        //setChanged(true);
        setScheduleDay(event.target.value as number);
    };

    const updateAdvanceExpression = (value:string) => {
        setAdvanceExpression(value); 
        props.updateExpression(props.source,value);
    }

    const cronErrorhandle = (e:CronError) => {
        if (e?.type === "invalid_cron") {
            setCronErrorMessage(e?.description);
        } else {
            setCronErrorMessage('');
        }
    }

    useEffect(() => { 
        if (props.initialExpression) {
            const arrExpr = props.initialExpression.split(" ");
            // if (arrExpr[0] !== "*" && arrExpr[1] !== "*") {
            //     if (arrExpr[2] === "*" && arrExpr[3] === "*" && arrExpr[4] === "*") {
            //         setScheduleMinute(arrExpr[0])
            //         setScheduleHour(arrExpr[1])
            //     } else if (arrExpr[2] === "*" && arrExpr[3] === "*" && arrExpr[4] !== "*") {
            //         setScheduleDay(arrExpr[4])
            //         setScheduleMinute(arrExpr[0])
            //         setScheduleHour(arrExpr[1])
            //     } else if (arrExpr[2] !== "*" && arrExpr[3] === "*" && arrExpr[4] === "*") {
            //         setScheduleDate(arrExpr[2])
            //         setScheduleMinute(arrExpr[0])
            //         setScheduleHour(arrExpr[1])
            //     }
            //     else {
            //         //setFrequency(4)
            //         setAdvanceExpression(props.initialExpression);
            //     }
            // }
            // else {
            //     //setFrequency(4)
            //     setAdvanceExpression(props.initialExpression);
            // }
            
            setScheduleMinute(parseInt(arrExpr[0]))
            setScheduleHour(parseInt(arrExpr[1]))
            setScheduleDate(parseInt(arrExpr[2]))
            setScheduleDay(parseInt(arrExpr[4]))
            
            // switch (frequency) {
            //     case 1:
            //         setScheduleMinute(arrExpr[0])
            //         setScheduleHour(arrExpr[1])
            //         break;
            //     case 2:
            //         setScheduleDay(arrExpr[4])
            //         setScheduleMinute(arrExpr[0])
            //         setScheduleHour(arrExpr[1])
            //         break;
            //     case 3:
            //         setScheduleDate(arrExpr[2])
            //         setScheduleMinute(arrExpr[0])
            //         setScheduleHour(arrExpr[1])
            //         break;
            //     default:
            //         setAdvanceExpression(props.initialExpression);
            // }
        }
    }, [props.initialExpression])

    useEffect(() => {
        //if (changed) {
            let expr = "";
            switch (frequency) {
                case 1:
                    expr = scheduleMinute + ' ' + scheduleHour + ' * * *'
                    break;
                case 2:
                    expr = scheduleMinute + ' ' + scheduleHour + ' * * ' + scheduleDay;
                    break;
                case 3:
                    expr = scheduleMinute + ' ' + scheduleHour + ' ' + scheduleDate + ' * *';
                    break;
                case 4:
                    expr = scheduleMinute + ' ' + scheduleHour + ' ' + scheduleDate + ' * ' + scheduleDay;
                    break;
                default:
                    break;
            }
        props.updateExpression(props.source, expr);
        //}
    }, [scheduleHour, scheduleMinute, scheduleDate, scheduleDay, frequency]);
    
    useEffect(() => {
        if (frequency === 4) {
            setAdvanceExpression(props.initialExpression ? props.initialExpression : "* * * * *")
        }
    }, [frequency])
    
    return (
        <Grid container spacing={2} data-test="scheduler-freq-main">
            <Grid item xs={2} sm={2} className={frequency !== 4 ? classes.title : undefined}>
                <Typography variant="body2">
                    {props.title}
                </Typography>
                {/* <Typography variant="body2" style={{marginLeft: '2px', fontStyle: 'italic' }}>{props.subTitle}</Typography> */}
                <Typography variant="body2" style={{marginLeft: '4px'}}>{props.subTitle}</Typography>
            </Grid>
            <Grid item xs={10} sm={10}>
                <Grid container spacing={2}>
            {
                frequency !== 4 &&
                <>
                    {
                        frequency === 3 &&
                        <Grid item>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.date')}</InputLabel>
                                <Select
                                    value={scheduleDate}
                                    onChange={handleScheduleDateChange}
                                    label={t('Backup.SettingsTab.date')}
                                    MenuProps={{ disableScrollLock: true }}
                                    data-test="select-date"
                                >
                                    {
                                        Array(31).fill('*').map((_, i) => (
                                            <MenuItem key={i} value={i + 1}>  {i + 1}  </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {
                        frequency === 2 &&
                        <Grid item>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.day')}</InputLabel>
                                <Select
                                    value={scheduleDay}
                                    onChange={handleScheduleDayChange}
                                    label={t('Backup.SettingsTab.day')}
                                    data-test="select-day"
                                >
                                    {
                                        AppConstants.Weekdays.map((day, i) => (
                                            <MenuItem key={i} value={i}> {day} </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.hour')}</InputLabel>
                            <Select
                                value={scheduleHour}
                                onChange={handleScheduleHourChange}
                                label={t('Backup.SettingsTab.hour')}
                                data-test="select-hour"
                            >
                                {
                                    Array(24).fill('*').map((_, i) => (
                                        <MenuItem key={i} value={i}>  {i}  </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.minute')}</InputLabel>
                            <Select
                                value={scheduleMinute}
                                onChange={handleScheduleMinuteChange}
                                label={t('Backup.SettingsTab.minute')}
                                data-test="select-minute"
                            >
                                {
                                    Array(60).fill('*').map((_, i) => (
                                        <MenuItem key={i} value={i}>  {i}  </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            }
            {
                frequency === 4 &&
                <>
                    <Grid item>
                        <Grid container spacing={2} direction="column">
                            {advanceExpression && isValidCron(advanceExpression) && 
                                <Grid item>
                                    <Typography variant="h5">{cronstrue.toString(advanceExpression, { throwExceptionOnParseError: false })}</Typography>
                                </Grid>
                            }
                            {
                                cronErrorMessage && 
                                <Grid item>
                                    <Typography variant="h5" color="secondary"> {cronErrorMessage} </Typography>
                                </Grid>
                            }
                            <Grid item>
                            <Cron value={advanceExpression||""} setValue={updateAdvanceExpression}
                                onError={cronErrorhandle}
                            />
                            </Grid>
                            <Grid item className={classes.title}>
                                <label className="m-r-10">Expression</label>
                                <div style={{maxWidth:'250px'}}>
                                    <AntdInput
                                        value={advanceExpression ? advanceExpression : ""}
                                        onBlur={(event) => updateAdvanceExpression(event.target.value)}
                                        onChange={(event) => updateAdvanceExpression(event.target.value)}
                                        allowClear={true}
                                        data-test="cron-expression-input"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

interface Iprops extends PropsFromRedux{
    isHelmEnv:boolean
}

export interface Log {
    datetime: Date;
    log: string;
    namespace: string;
    owner: string;
    podname: string;
    status: string;
    type: string;
}
export function ScheduleEnv(props:Iprops) {
    const [t] = useTranslation()
    const classes = useStyles();
    const envRole = props.isHelmEnv ? { name: "Admin" } : props.envRole
    const [enable, setEnable] = React.useState(false);
    const [startCronExpression, setStartCronExpression] = React.useState<string>("");
    const [stopCronExpression, setStopCronExpression] = React.useState<string>("");
    const [frequency, setFrequency] = React.useState(1);
    //const [scheduleType, setScheduleType] = React.useState();
    //const [selectedTimezone, setSelectedTimezone] = React.useState({})
    const { envScheduleLogs } = props;
    const envDetails = props.isHelmEnv ? props.helmEnvironmentDetails : props.environmentDetails;

    const handleFrequencyChange = (e:React.ChangeEvent<{ value: unknown }>) => {
        setFrequency(e.target.value as number);
        //setScheduleType(String(e.target.value));
    }

    const isFormValid = () => {
        let isValid = false;
        if (envDetails?.schedules) {
            if (envDetails.schedules.enabled !== enable) {
                if (!enable) {
                    isValid = true;
                }
                if (enable && startCronExpression && stopCronExpression) {
                    isValid = true;
                }
            }
            if (envDetails.schedules.enabled) {
                if (startCronExpression !== envDetails.schedules.start || stopCronExpression !== envDetails.schedules.stop) {
                    isValid = true;
                }
            }
        }
        else {
            if (enable && startCronExpression && stopCronExpression && (startCronExpression !== stopCronExpression))
                isValid = true;
        }
        return isValid;
    }

    useEffect(() => {
        if (envDetails && envDetails.schedules) { 
            const { schedules } = envDetails;
            setEnable(schedules.enabled);
            setFrequency(schedules.type ? parseInt(schedules.type) : 1);
            setStartCronExpression(schedules.start);
            setStopCronExpression(schedules.stop);

            if (schedules.enabled) {
                props.fetchScheduleLog(envDetails.id, props.isHelmEnv);
            }
        }
    }, [envDetails]);

    const saveScheduleSettings = () => {
        const jsonBody:SchedulesModel = {
            enabled: enable,
            start: startCronExpression,
            stop: stopCronExpression,
            time_zone: "Etc/GMT",
            type: frequency.toString()
        }
        props.updateEnvSchedule(envDetails.id, jsonBody, props.isHelmEnv);
    }

    const updateCronExpression = (source:number, value:string) => {
        if (source === 1) {
            setStopCronExpression(value);            
        }
        else { 
            setStartCronExpression(value)
        }
    }

    const refreshHistory = () => { 
        props.fetchScheduleLog(envDetails.id, props.isHelmEnv);
    }

    return (
        <div data-test="schedulerTabContainer">
            <Card elevation={1}>
                <CardHeader
                    title={<Typography variant="h6">{t('Environment.SchedulerEnv.title')}</Typography>}
                    action={
                        <Grid item xs={12}>
                            <FormControlLabel
                                data-test="switch"
                                control={
                                    <Switch
                                        data-test="enable-scheduler"
                                        name="enable"
                                        color="primary"
                                        checked={enable}
                                        onChange={e => setEnable(e.target.checked)}
                                        disabled={!isAuthorized("update", envRole.name)}
                                    />
                                }
                                label={<Typography variant="body2">{t('Environment.SchedulerEnv.enableLabel')}</Typography>}
                            />
                        </Grid>
                    }
                />
                <Collapse in={enable || envDetails?.schedules?.enabled}>
                    <Divider />
                    <Box style={{padding: 12}}>
                        <CardContent>
                            <Grid container spacing={3}>
                                {enable &&
                                    <>
                                        <Grid item xs={12} data-test="frequency">
                                            <Grid container spacing={2}>
                                                <Grid item xs={2} sm={2} className={classes.title}>
                                                    <Typography variant="body2">
                                                        {t('Backup.SettingsTab.frequencyonly')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10} sm={10}>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        {/* <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.frequency')} </InputLabel> */}
                                                        <Select
                                                            value={frequency}
                                                            onChange={handleFrequencyChange}
                                                            MenuProps={{ disableScrollLock: true }}
                                                            //label={t('Backup.SettingsTab.frequency')}
                                                            data-test="select-frequency"
                                                        >
                                                            <MenuItem value={1}> Daily </MenuItem>
                                                            <MenuItem value={2}> Weekly </MenuItem>
                                                            <MenuItem value={3}> Monthly </MenuItem>
                                                            <MenuItem value={4}> Advanced </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} data-test="cron-grid-start" style={frequency === 4 ? { marginTop: '25px' } : {}}>
                                            <ScheduleFrequency frequency={frequency} title="Start" subTitle="(in UTC)" source={2} updateExpression={updateCronExpression} initialExpression={envDetails?.schedules?.start} data-test="corn-freq-start"/>
                                        </Grid>
                                        <Grid item xs={12} data-test="cron-grid-stop" style={frequency === 4 ? { marginTop: '25px' } : {}}>
                                            <ScheduleFrequency frequency={frequency} title="Stop" subTitle="(in UTC)" source={1} updateExpression={updateCronExpression} initialExpression={envDetails?.schedules?.stop} data-test="corn-freq-stop"/>
                                        </Grid>
                                    
                                        {/* <Grid item xs={12}>
                                            <TimezoneSelect
                                                value={selectedTimezone}
                                                onChange={setSelectedTimezone}
                                                labelStyle='abbrev'
                                            />
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <Paper elevation={8}>
                                                <Alert severity="info" className={classes.infoBackground}>
                                                    <Typography variant='body2' className={classes.infoText}>Scheduler will run based on server timezone (UTC)</Typography>
                                                </Alert>
                                            </Paper>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </CardContent>
                        {isAuthorized("update", envRole.name) &&
                            <CardActions>
                                <Button data-test="save-btn" color="primary" variant="contained" onClick={() => saveScheduleSettings()} disabled={!isFormValid()} style={{marginLeft: '6px'}}>
                                    {t('Backup.SettingsTab.save')}
                                </Button>
                            </CardActions>
                        }
                    </Box>
                </Collapse>
                {/* <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                data-test="switch"
                                control={
                                    <Switch
                                        data-test="enable-scheduler"
                                        name="enable"
                                        color="primary"
                                        checked={enable}
                                        onChange={e => setEnable(e.target.checked)}
                                        disabled={!isAuthorized("update", envRole.name)}
                                    />
                                }
                                label={t('Environment.SchedulerEnv.enableLabel')}
                            />
                        </Grid>
                        {
                            enable &&
                            <>
                                <Grid item xs={12} data-test="frequency">
                                    <Grid container spacing={2}>
                                        <Grid item xs={2} sm={1} className={classes.title}>
                                            <Typography variant="h5">
                                                {t('Backup.SettingsTab.frequencyonly')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sm={11}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-schedule-native-simple"> {t('Backup.SettingsTab.frequency')} </InputLabel>
                                                <Select
                                                    value={frequency}
                                                    onChange={handleFrequencyChange}
                                                    MenuProps={{ disableScrollLock: true }}
                                                    //label={t('Backup.SettingsTab.frequency')}
                                                    data-test="select-frequency"
                                                >
                                                    <MenuItem value={1}> Daily </MenuItem>
                                                    <MenuItem value={2}> Weekly </MenuItem>
                                                    <MenuItem value={3}> Monthly </MenuItem>
                                                    <MenuItem value={4}> Advanced </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} data-test="cron-grid-start" style={frequency === 4 ? { marginTop: '25px' } : {}}>
                                    <ScheduleFrequency frequency={frequency} title="Start" subTitle="(in UTC)" source={2} updateExpression={updateCronExpression} initialExpression={envDetails?.schedules?.start} data-test="corn-freq-start"/>
                                </Grid>
                                <Grid item xs={12} data-test="cron-grid-stop" style={frequency === 4 ? { marginTop: '25px' } : {}}>
                                    <ScheduleFrequency frequency={frequency} title="Stop" subTitle="(in UTC)" source={1} updateExpression={updateCronExpression} initialExpression={envDetails?.schedules?.stop} data-test="corn-freq-stop"/>
                                </Grid>
                             
                                <Grid item xs={12}>
                                    <TimezoneSelect
                                        value={selectedTimezone}
                                        onChange={setSelectedTimezone}
                                        labelStyle='abbrev'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        Scheduler will run based on server timezone (UTC)
                                    </Alert>
                                </Grid>
                            </>
                        }
                    </Grid>
                </CardContent> */}
                {/* <Divider /> */}
                {/* {
                    isAuthorized("update", envRole.name) &&
                    <CardActions>
                        <Button data-test="save-btn" color="primary" variant="contained" onClick={() => saveScheduleSettings()} disabled={!isFormValid()}>
                            {t('Backup.SettingsTab.save')}
                        </Button>
                    </CardActions>
                } */}
            </Card>
            {
                envDetails?.schedules?.enabled && 
                <>
                    <Grid container data-test="cronJobHistory" justify="space-between" alignItems="center">
                        <Typography variant="h5" className='envTitle m-t-20'>{t('CronJob.CronJobInfo.history')}</Typography>
                        <IconButton data-test="refreshButton" className='m-t-20' onClick={() => refreshHistory()}>
                            <RefreshIcon color="primary" />
                        </IconButton>
                    </Grid>
                    {
                        !envScheduleLogs && [1, 2, 3, 4].map((item, ind) => (<CronJobSkeleton  key={ind} data-test="cronJobSkeleon" />))
                    }
                    {
                        envScheduleLogs && envScheduleLogs.length === 0 &&
                        <Grid item xs className="imgGrid">
                            <p data-test="no-log-message">{t('CronJob.CronJobInfo.noHistoryEnvScheduler')}</p>
                        </Grid>
                    }
                    {
                        envScheduleLogs && envScheduleLogs.length > 0 &&
                        envScheduleLogs.map((log:Log, index:number) => {
                            return (
                                <div data-test="cronJobLogContainer" key={index}>
                                    <ExpansionPanel className="m-t-20">
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon fontSize="small" />}
                                            aria-controls="panel1a-content"
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item style={{ alignSelf: 'center' }}>
                                                    <FilledStatusIndicator status={log.status} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h5" color="primary" className="oneLine" >{getDateInStandardFormat(log.datetime)} </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <AceEditor
                                                mode="javascript"
                                                theme="monokai"
                                                fontSize={14}
                                                showPrintMargin={false}
                                                width="100%"
                                                height="200px"
                                                readOnly={true}
                                                value={log.log}
                                                setOptions={{
                                                    showLineNumbers: true,
                                                    tabSize: 4
                                                }}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            )
                        })
                    }
                </>
            }
            {/* <ConfirmActionPopup
                open={confirmUpdate}
                handleAgree={handleEnvironmentUpdate}
                handleDisAgree={updateDisagreeHandler}
                message={t('Environment.ResourcesTab.sureUpdateScripts')}
                yesText={t('Projects.VariablesTab.yesText')}
                noText={t('Projects.VariablesTab.noText')}
            /> */}
            {props.updatingEnvSchedule && <BackdropLoader message={"Updating Environment Scheduler"}  data-test="backDrop-loader"/>}
        </div>
    );
}

const mapStateToProps = (state:any) => ({
    environmentDetails: state.EnvironmentReducer.environmentDetails,
    helmEnvironmentDetails: state.HelmEnvironmentReducer.helmEnvironmentDetails,
    envRole: state.EnvironmentReducer.envRole,
    updatingEnvSchedule: state.EnvironmentReducer.updatingEnvSchedule,
    envScheduleLogs: state.EnvironmentReducer.envScheduleLogs
});

const mapDispatchtoProps = (dispatch:Dispatch) => {
    return {
        updateEnvSchedule: (id: number, jsonBody: SchedulesModel, isHelm:boolean) => dispatch(updateEnvSchedule(id, jsonBody, isHelm)),
        fetchScheduleLog: (id:number, isHelm:boolean) => dispatch(fetchScheduleLog(id, isHelm))
    };
};
const connector=connect(mapStateToProps, mapDispatchtoProps)
export default connector(ScheduleEnv);
type PropsFromRedux=ConnectedProps<typeof connector>
