import palette from "../palette";

export default {
  outlined: {
    fontSize: '12px',
    '&.Mui-focused': {
      color: `${palette.text.secondary} !important`,
      fontWeight: 600,
      fontSize: '12px',
      borderColor: `${palette.background.neutral} !important`,
      backgroundColor: 'transparent !important', 
      letterSpacing: '-0.6px'
    },
    '&.Mui-filled': {
      color: `${palette.text.secondary} !important`,
      fontWeight: 600,
      fontSize: '12px',
      borderColor: `${palette.background.neutral} !important`,
      backgroundColor: 'transparent !important',
      letterSpacing: '-0.6px' 
    },
    '&.MuiInputLabel-shrink': {
      color: `${palette.text.secondary} !important`,
      fontWeight: 600,
      fontSize: '12px',
      backgroundColor: 'transparent !important', 
      letterSpacing: '-0.6px',
      padding: '0px 2px'
    },
  },
  root:{
    backgroundColor: 'transparent'
  }
};
