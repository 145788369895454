import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import "./LabelHandler.css";
import { getLabelStyles } from "../../helpers/utils.ext";

type Props = {
  label: string | number | JSX.Element
  icon: JSX.Element
  iconTooltip: string
  labelTooltip?: string
  labelType?: string
  variant?: 'inherit'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6'|'subtitle1'|'subtitle2'|'body1'|'body2'|'caption'|'overline'
  className? : string
  labelClassName? : string
}

export const LabelHandler = ({ label, icon, iconTooltip, labelTooltip, labelType , variant, className, labelClassName}: Props) => {
  const iconComponent = React.cloneElement(icon, {
    className: "labelsvgicon",
  });

  const labelColor = getLabelStyles(labelType);

  const Label = () => (
    <span className="topinfoGrid">
      <Typography
        variant= {variant}
        className={`${labelClassName ? labelClassName  : ""} label`}
        style={{color: labelColor.color}}
      >
        {label !== "" && label ? label : <Skeleton width={80} />}
      </Typography>
    </span>
  );

  return (
    <div
      data-test="main-container"
      className={className ? className : "label-handler-container" }
      style={{backgroundColor: labelColor.backgroundColor, border: `0.6px solid ${labelColor.color}`}}
    >
      {iconComponent && (
        <Tooltip title={iconTooltip} arrow data-test="icon-tooltip">
          {iconComponent}
        </Tooltip>
      )}
      {labelTooltip ? (
        <Tooltip title={labelTooltip} arrow data-test="label-tooltip">
          <Label data-test="label-comp" />
        </Tooltip>
      ) : (
        <Label data-test="label-comp"/>
      )}
    </div>
  );
};