import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Button,
  Typography,
  IconButton,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { ScannedList } from "./ScannedList";
import NewScan from "./NewScan";
import SecurityDetails from "./SecurityDetails";
import { Skeleton } from "@material-ui/lab";
import RefreshIcon from "@material-ui/icons/Refresh";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import { clearScanList, fetchScanList } from "../../redux/actions";
import palette from "../../../../theme/palette";

const useStyles = makeStyles({
  margin: {
    marginTop: "30px",
  },
});

interface Props extends PropsFromRedux {}

function Security(props: Props) {
  const classes = useStyles();

  const [mode, setMode] = useState(1);
  const [listID, setListID] = useState(0);

  useEffect(() => {
    props.fetchScanList(props.environmentDetails.id);
    return ()=>{
      props.clearScanList()
    }
  }, []);

  const handleRunScan = () => {
    setMode(2);
  };

  const handleInfoBack = () => {
    props.fetchScanList(props.environmentDetails.id);
    setMode(1);
  };

  const openDetails = (listId: number) => {
    setMode(3);
    setListID(listId);
  };

  const handleRefresh = () => {
    props.fetchScanList(props.environmentDetails.id);
  };

  return (
    <div className={(mode === 1 && (props.scannerList !== null && props.scannerList.length > 0)) || mode === 3 ? "alertwhitecontainer" : ""}>
      {mode === 1 && (
        <>
          <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant={mode === 1 && (props.scannerList !== null && props.scannerList.length > 0) ? "subtitle1" : "h6"}>Security</Typography>
            </Grid>
            <Grid item>
              <IconButton
                title="Refresh Insights"
                color="primary"
                aria-label="Refresh"
                onClick={() => handleRefresh()}
                data-test="refresh-button"
              >
                <RefreshIcon style={{ fontSize: 25 }} />
              </IconButton>
              <Button
                className="m-l-10"
                onClick={() => handleRunScan()}
                color="primary"
                variant="contained"
                size="medium"
              >
                RUN SCAN
              </Button>
            </Grid>
          </Grid>

          {!props.scannerList && (
            <Grid
              container
              spacing={3}
              className={classes.margin}
              direction="column"
            >
              <Grid item>
                <Skeleton variant="rect" height={40} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" height={40} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" height={40} />
              </Grid>
            </Grid>
          )}

          <div className={classes.margin}>
            {Array.isArray(props.scannerList) && props.scannerList.length > 0 ? (
              <Grid container spacing={2}>
                {props.scannerList.map((item: any, ind: number) => (
                  <Grid item xs={12} md={4} key={ind}>
                    <ScannedList openDetails={openDetails} list={item} serialNo={ind} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <>
                {!props.fetchingScannerList && (
                  // <Grid
                  //   container
                  //   justify="center"
                  //   alignItems="center"
                  //   direction="column"
                  //   spacing={2}
                  // >
                  //   <Grid item>
                  //     <Typography variant="h5">
                  //       No scan reports, start a new scan
                  //     </Typography>
                  //   </Grid>
                  //   <Grid item>
                  //     <Button
                  //       onClick={() => handleRunScan()}
                  //       color="primary"
                  //       variant="contained"
                  //       size="small"
                  //     >
                  //       Run Scan
                  //     </Button>
                  //   </Grid>
                  // </Grid>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={10}>
                      <Card elevation={1}>
                        <CardHeader title={<Typography variant="h6">Alerts</Typography>}/>
                        <Divider />
                        <CardContent>
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            direction="column"
                            spacing={2}
                          >
                            <Grid item>
                              <Typography variant="h6">
                                No scan reports, start a new scan
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button
                                onClick={() => handleRunScan()}
                                color="primary"
                                variant="contained"
                                size="small"
                              >
                                Run Scan
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </div>
        </>
      )}
      {mode === 2 && (
        <div>
          <NewScan handleBack={handleInfoBack} />
        </div>
      )}
      {mode === 3 && (
        <div>
          <SecurityDetails listID={listID} handleBack={handleInfoBack} />
        </div>
      )}

      {props.fetchingScannerList && (
        <BackdropLoader message="fetching security details ... " />
      )}
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  scannerList: state.EnvironmentReducer.scannerList,
  fetchingScannerList: state.EnvironmentReducer.fetchingScannerList,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchScanList: (id: number) => dispatch(fetchScanList(id)),
  clearScanList: () => dispatch(clearScanList()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Security);
type PropsFromRedux = ConnectedProps<typeof connector>;
