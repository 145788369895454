import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { getPaymentHistory } from "../account/billingtab/redux/actions";
import { ConnectedProps, connect } from "react-redux";
import * as H from 'history';
import _ from "lodash";
import { BillingHistory } from "../../models/Billing.model";
import { getDateBilling } from "../../helpers/utils";
interface I_props
    extends PropsFromRedux,
    RouteComponentProps<{}, StaticContext> {
    handleDueWaring: (isDueVisible: boolean) => void
}

export const DueWarning = (props: I_props) => {
    const [shouldWarn, setShouldWarn] = useState(false);
    const [dueDate,setDueDate] = useState("");

    useEffect(() => {
        // call payment api once from breadcrum
        props.getPaymentHistory(10, 1);
    }, []);

    const isPaymentDue = (paymentHistoryList: BillingHistory[]): boolean => {
        if(!paymentHistoryList.length) return false
        return parseFloat(paymentHistoryList[0].balance.toFixed(2))>= 0.00 ? false : true;
    };

    const gotoUrl = (route: H.LocationDescriptor) => () => {
        props.history.push(route)
    }

    const hideWarning = () => {
        props.handleDueWaring(false)
    }

    useEffect(() => {
        // look for change in payment history and show waring if required else hide
        if (!props.paymentHistory || !props.paymentHistory.data.length) return;
        let paymentHistoryList = props.paymentHistory.data;

        let isDueDate = getDateBilling(props.paymentHistory.data[0].date)

        setDueDate(isDueDate)

        setShouldWarn(isPaymentDue(paymentHistoryList))

    }, [props.paymentHistory])

    

    return (
        <>
            {(shouldWarn && dueDate) && (
                <Alert onClose={hideWarning} severity="error" color="error" style={{marginRight: 15, marginTop: 10}}>

                    <Typography variant="subtitle1">
                        Your 01Cloud invoice is due by {dueDate}. Kindly complete the payment to ensure uninterrupted service. 
                    </Typography>

                    {props.location.pathname + props.location.hash !==
                        "/account#billing" && (
                            <span
                                onClick={gotoUrl("/account#billing")}
                            >
                                <Typography
                                    style={{
                                        cursor: "pointer",
                                        color: "#357dfd",
                                        textDecoration: "underline",
                                    }}
                                    variant="subtitle1"
                                >
                                    Make a Payment
                                </Typography>
                            </span>
                        )}
                </Alert>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => ({
    paymentHistory: state.PaymentReducer.paymentHistory,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
    return {
        getPaymentHistory: (size: number, page: number) =>
            dispatch(getPaymentHistory(size, page)),
    };
};
const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(DueWarning));
