import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton'
import { getDiffDays, getDateInStandardFormat } from '../../helpers/utils'
import { useTranslation } from 'react-i18next'
import "./DateHandler.css";
import { getLabelStyles } from '../../helpers/utils.ext';

type Props = {
    date: string,
    icon?: JSX.Element,
    labelType? : string,
    className? : string,
    labelClassName? : string,
    variant?: 'inherit'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6'|'subtitle1'|'subtitle2'|'body1'|'body2'|'caption'|'overline'
}

export const DateHandler = (props: Props) => {
    const [t] = useTranslation()
    const labelColor = getLabelStyles(props.labelType);
    const iconComponent = props.icon && React.cloneElement(props.icon, {
        className: "labelsvgicon"
    });

    return (
            <div data-test="date-container" className={props.className ? props.className : "date-label-container"} style={{backgroundColor: labelColor.backgroundColor, border: `0.6px solid ${labelColor.color}`}}>
            {iconComponent &&
                <Tooltip title={t('Projects.LoadbalancerInfo.createdLabel')} arrow data-test="tool-tip">
                    {iconComponent}
                </Tooltip>
            }
                <span className="topinfoGrid" title={getDateInStandardFormat(props.date)} data-test="top-info">
                    <Typography color="textPrimary" variant={props.variant ? props.variant : "body2"} className={props.labelClassName? props.labelClassName : "label"} style={{color: labelColor.color}}>
                        {props.date ? `${getDiffDays(props.date, true)}` : <Skeleton width={80} />}
                    </Typography>
                </span>
            </div>
    )
}
