import React, { Component } from 'react'
//import { withTranslation } from 'react-i18next';
import { Button,
         withStyles,
         Grid,
         Paper,
         Typography,
         FormControl,
         MenuItem,
         Select,
         createStyles,
         useMediaQuery
       } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Skeleton } from "@material-ui/lab";
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithStyles } from "@material-ui/styles";
import { IOtherVersion, PluginVersionModel } from '../../../../models/Environment.model';
import { RocketLaunchIcon } from '../../../../helpers/customIcon';
import palette from '../../../../theme/palette';

/* istanbul ignore next */
const useStyles = () => createStyles({
    actions: {
        display: 'flex',
        justifyContent: 'center'
    },
    container:{
        marginTop: 0,
        minHeight : '20rem',
    },
    paperContainer: {
        // height: 350,
        width:'100%',
        display: 'flex',
        flexDirection : 'column',
    },
    icon:{
        fontSize:85
    },
    iconContainer: {
     flex : 1,
     alignItems: 'center',
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'flex-end',
    },
    selectbox: {
        height: 30
    },
    formControl : {
        minWidth : '100%',
    },
    buttonsContainer : {
        padding: '12px 24px 24px',
        // display: 'flex', 
        // flexDirection: 'column',
        // flex : 1,
        // justifyContent : 'space-between',
        // padding : '1rem',
    },
    loadingIndicator : {
        alignSelf : 'center'
    },
    continueButton : {
        marginTop : '2rem'
    },
    logo: {
        height: '12vh',
        width:'auto',
        objectFit:'cover',
        padding:'10px 0'
    },
    otherversion:{
        // marginTop:'20px'
    },
    center:{
        justifyContent:'center'
    },
    dark:{
        marginBottom: '12px'
        // color:'#000000'
    },
    description: {
        color: palette.text.secondary,
    }
});

interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles> {
    versionsList:PluginVersionModel;
    handleChange:(currentStep:number,data:any)=>void;
    activeStep:number;
}

type modifiedVersion = {
    selectedVersion: string; 
    name: string; 
    versions:Pick<IOtherVersion,"info"|"name"|"released"|"repo"|"tag">[];
}

type State = {
    currentStep : number;
    selectedVersion : string;
    selectedVersionId : number;
    otherVersions:modifiedVersion[];
}

export class Template extends Component<Props, State> {
    constructor(props:Props){
        super(props);
        this.state = {
            currentStep : 0,
            selectedVersion : this.props?.versionsList?.versions  && this.props.versionsList?.versions[0]?.versions?.length > 0 ? this.props.versionsList.versions[0].versions[0].name : 'Version',
            selectedVersionId : 0,
            otherVersions: []
        
        }
    }

    componentDidMount(){
        if(this.props.versionsList?.versions?.length > 0){
            let all_versions = this.props.versionsList.versions
            let other_versions = all_versions.slice(1, all_versions.length)

            let modified_versions:modifiedVersion[] = []
            other_versions.forEach(ver => {
                const _ver = {
                    ...ver,
                    selectedVersion: ver.versions?.length > 0 ? ver.versions[0].name : ""
                }
                modified_versions.push(_ver)
            })
            this.setState({
                otherVersions: modified_versions
            })    
        }
        
    }

    UNSAFE_componentWillReceiveProps = (newProps:Props) => {
        if(newProps.versionsList?.id > 0)
        {
            if (!this.props.versionsList?.id || this.props.versionsList?.id !== newProps.versionsList?.id) 
            {
                if(newProps.versionsList?.versions && newProps.versionsList?.versions[0] && newProps.versionsList.versions[0].versions?.length > 0)
                {
                    this.setState({
                        selectedVersion: newProps.versionsList.versions[0].versions[0].name,
                    })           
                }

                if(newProps.versionsList?.versions?.length > 0){
                    let all_versions = newProps.versionsList.versions
                    let other_versions = all_versions.slice(1, all_versions.length)

                    let modified_versions:modifiedVersion[] = []
                    other_versions.forEach(ver => {
                        const _ver = {
                            ...ver,
                            selectedVersion: ver.versions?.length > 0 ? ver.versions[0].name : ""
                        }
                        modified_versions.push(_ver)
                    })
                    this.setState({
                        otherVersions: modified_versions
                    })    
                }
            }
        }
    }

    handleContinue = () => {
        const { pluginDetails , versionsList } = this.props;
        const {  selectedVersion, otherVersions } = this.state;
        const _version = versionsList?.versions?.length > 0 && versionsList?.versions[0]?.versions && versionsList.versions[0].versions.find(versionDetails => versionDetails.name === selectedVersion)
        if(_version) {
            const data:any = {
                templateName : pluginDetails.name,
                selectedVersion,
                pluginId: pluginDetails.id,
                versionId: versionsList.id,
                version : _version
            }

            const other_version = otherVersions.map(ver => {
                const other_version_data = {
                    deployment_name: ver.name,
                    // selectedVersion: ver.selectedVersion,
                    ...ver.versions.find(_ver => _ver.name === ver.selectedVersion)
                }
                return other_version_data
            })
            data.other_version = other_version

            this.props.handleChange(this.state.currentStep + 1, data)
        }
    }
    
    versionChangeHandler = (e: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            selectedVersion: e.target.value as string
        })
    }

    otherVersionChangeHandler = (e: React.ChangeEvent<{ value: unknown }>, ver:modifiedVersion) => {
        const newOtherVersions = this.state.otherVersions?.map(ov => {
            if(ov.name === ver.name){
                ov.selectedVersion = e.target.value as string
            }
            return ov
        })
        this.setState({
            otherVersions: newOtherVersions
        })
    }

    render () {

        const { classes,versionsList, pluginDetails, t } = this.props;
        const { selectedVersion } = this.state;
        if (this.props.activeStep !== this.state.currentStep) {
            return null
        }
        
        return (
            <div data-test="main-container">
                <Grid container spacing={ 2 } alignItems='center' justify='center' className={ classes.container }>
                    {/* {
                        isLoading && 
                                
                                    <Grid item md={ 3 } sm= { 6 } xs={ 12 }>
                                    <SkeletonTheme height={100} width={100}> 
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton width={100}/>
                                                    </div>
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton width={100}/>
                                                    </div>
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton width={100}/>
                                                    </div>
                                                </SkeletonTheme>
                                    </Grid>
                                
                } */}
                        
                    {/* <Grid item xs sm={6} md={4} lg={4} key={pluginDetails.id } style={{textAlign:'center'}}>
                        <Paper className={ classes.paperContainer } elevation={ 3 }>

                            <div>
                                {
                                        Object.keys(pluginDetails).length === 0 && (
                                        <div data-test="skeleton" className='m-t-10'>
                                                <div className='oneRemMarginBottomSeperator alignCenter'>
                                                    <Skeleton variant='circle' height={80} width={80} />
                                                </div>
                                                <div className='oneRemMarginBottomSeperator alignCenter'>
                                                    <Skeleton width='20%' height={20}/>
                                                </div>
                                                <div className='oneRemMarginBottomSeperator alignCenter'>
                                                    <Skeleton width='90%' height={15} />
                                                    
                                                </div>
                                                <div className='oneRemMarginBottomSeperator alignCenter'>
                                                    <Skeleton width='70%' height={15} />
                                                </div>
                                        </div>
                                        )
                                    }
                                { Object.keys(pluginDetails).length !== 0 && <img data-test="env-image" src={ pluginDetails ? pluginDetails.image : "" } className={classes.logo} alt=''/> }
                                <Typography align="center" variant="h6" className={classes.dark} data-test="env-name">{pluginDetails.name}</Typography>
                            </div>

                            <div className={ classes.buttonsContainer }>
                                <Typography align="center" variant="body2" className="disctxt" data-test="env-desc" >
                                    {pluginDetails.description}
                                </Typography>
                                <div className='center'>
                                    <FormControl className={ classes.formControl } data-test="env-version-select">
                                        <Select
                                          data-test="version-select"
                                          variant='outlined'
                                          color='primary'
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={ selectedVersion }
                                        //   gutterButtom
                                          onChange={ (e: React.ChangeEvent<{ value: unknown }>) => this.versionChangeHandler(e) }
                                          MenuProps={{
                                                    disableScrollLock: true,
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    }
                                                }}
                                        >
                                            {
                                                    (!versionsList || !versionsList.versions || versionsList.versions.length === 0) && 
                                                    <MenuItem  value={ 'Version' }>{t('Environment.CreateAppMaster.version')}</MenuItem>
                                                }
                                            {versionsList &&
                                                    versionsList.versions &&
                                                    versionsList.versions[0] &&
                                                    versionsList.versions[0].versions &&
                                                    versionsList.versions[0].versions.map((versionDetails, ind) => (
                                                        <MenuItem value={versionDetails.name} key={ind}>
                                                            {versionDetails.name}
                                                        </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                   
                                {
                                        this.state.otherVersions?.length > 0 && this.state.otherVersions.map((ver, ind) => (
                                         
                                            <div className={classes.otherversion} key={ ind }>
                                                  
                                                <div key={ver.name}>
                                                    <Typography align="center" variant="h6" className={classes.dark} data-test="other-env-name">{ver.name}</Typography>
                                             
                                                    <FormControl className={ classes.formControl } data-test="other-env-version">
                                                        <Select
                                                          variant='outlined'
                                                          color='primary'
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          data-test = "select-otherVersion"
                                                          value={ ver.selectedVersion }
                                                          onChange={ (e) => {this.otherVersionChangeHandler(e, ver ) } }
                                                          MenuProps={{
                                                                disableScrollLock: true,
                                                                getContentAnchorEl: null,
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left"
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                ver.versions?.length === 0 && 
                                                                <MenuItem  value={ 'Version' }>{t('Environment.CreateAppMaster.version')}</MenuItem>
                                                            }
                                                            {   
                                                                ver.versions?.map((versionDetails, _ind) => (
                                                                    <MenuItem value={versionDetails.name} key={_ind}>
                                                                        {versionDetails.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            
                                        ))
                                    }
                            </div>
                        </Paper>
                    </Grid> */}

                    <Grid item xs sm={this.state.otherVersions?.length > 0 ? 12 : 6} key={pluginDetails.id } style={{textAlign:'center'}}>
                        <Paper className={ classes.paperContainer } elevation={ 1 }>

                            <div style={{ padding: '24px 24px 12px' }}>
                                    {
                                        Object.keys(pluginDetails).length === 0 && (
                                        <div data-test="skeleton" className='m-t-10' style={{display: 'flex', gap: 24, width: '100%'}}>
                                                <div className='oneRemMarginBottomSeperator alignCenter'>
                                                    <Skeleton variant='circle' height={80} width={80} />
                                                </div>
                                                <div style={{width: '100%'}}>
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton width='20%' height={20}/>
                                                    </div>
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton width='90%' height={15} />
                                                        
                                                    </div>
                                                    <div className='oneRemMarginBottomSeperator'>
                                                        <Skeleton width='70%' height={15} />
                                                    </div>
                                                </div>
                                        </div>
                                        )
                                    }
                                { Object.keys(pluginDetails).length !== 0 && 
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item xs={12} lg={this.state.otherVersions?.length > 0 ? 1 : 2}>
                                            <img data-test="env-image" src={ pluginDetails ? pluginDetails.image : "" } className={classes.logo} alt=''/> 
                                        </Grid>
                                        <Grid item xs={12} lg={this.state.otherVersions?.length > 0 ? 8: 10}>
                                            <Typography align="left" variant="subtitle1" data-test="env-desc" className={classes.description}>
                                                {pluginDetails.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </div>

                            <Grid container spacing={3} className={ classes.buttonsContainer }>
                                   
                                <Grid item xs={12} md={this.state.otherVersions?.length > 0 ? 6 : 12}>
                                    <Typography align="left" variant="subtitle2" className={classes.dark} data-test="env-name">{pluginDetails.name}</Typography>
                                    <FormControl className={ classes.formControl } data-test="env-version-select">
                                        <Select
                                          data-test="version-select"
                                          variant='outlined'
                                          color='primary'
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={ selectedVersion }
                                        //   gutterButtom
                                          onChange={ (e: React.ChangeEvent<{ value: unknown }>) => this.versionChangeHandler(e) }
                                          MenuProps={{
                                                    disableScrollLock: true,
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '110px', 
                                                        }
                                                    }
                                                }}
                                        >
                                            {
                                                    (!versionsList || !versionsList.versions || versionsList.versions.length === 0) && 
                                                    <MenuItem  value={ 'Version' }>{t('Environment.CreateAppMaster.version')}</MenuItem>
                                                }
                                            {versionsList &&
                                                    versionsList.versions &&
                                                    versionsList.versions[0] &&
                                                    versionsList.versions[0].versions &&
                                                    versionsList.versions[0].versions.map((versionDetails, ind) => (
                                                        <MenuItem value={versionDetails.name} key={ind}>
                                                            {versionDetails.name}
                                                        </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    this.state.otherVersions?.length > 0 && this.state.otherVersions.map((ver, ind) => ( 
                                        <Grid item className={classes.otherversion} key={ ind } xs={12} md={6} lg={6}>
                                                
                                            <div key={ver.name}>
                                                <Typography align="left" variant="subtitle2" className={classes.dark} data-test="other-env-name">{ver.name}</Typography>
                                            
                                                <FormControl className={ classes.formControl } data-test="other-env-version">
                                                    <Select
                                                        variant='outlined'
                                                        color='primary'
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        data-test = "select-otherVersion"
                                                        value={ ver.selectedVersion }
                                                        onChange={ (e) => {this.otherVersionChangeHandler(e, ver ) } }
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                            getContentAnchorEl: null,
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            ver.versions?.length === 0 && 
                                                            <MenuItem  value={ 'Version' }>{t('Environment.CreateAppMaster.version')}</MenuItem>
                                                        }
                                                        {   
                                                            ver.versions?.map((versionDetails, _ind) => (
                                                                <MenuItem value={versionDetails.name} key={_ind}>
                                                                    {versionDetails.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>   
                                    ))
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                    
                </Grid>
                <div className={ classes.actions }>
                    <Button variant="contained" color='primary' disabled={this.props.versionsList ? false : true} className={ classes.continueButton } onClick={ () => this.handleContinue() } data-test="continue-btn">
                    {t('Environment.CreateAppMaster.continue')}
                    <RocketLaunchIcon style={{marginLeft:'8px'}}/>
                    </Button>
                </div>
            </div>
        )
    }
}

/* istanbul ignore next */
const mapDispatchtoProps = () => {
    return {
        //fetchPluginsListAction : () => dispatch(fetchPluginsList()),
        
    }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => {
    return {
        //pluginsList: state.CreateAppReducer.pluginsList,
        appDetails: state.AppsReducer.appDetails,
        versionsList: state.CreateAppReducer.versionsList,
        isLoading: state.CreateAppReducer.isLoading, 
        pluginDetails : state.AppsReducer.pluginDetails
    }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(compose(withStyles)(useStyles)(withTranslation()(Template)));