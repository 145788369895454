import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Collapse,
  Divider,
  Paper,
  CircularProgress,
  Theme
} from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import clsx from "clsx";
import { connect, ConnectedProps } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import OperatorInfoPopup from "../../components/operatorcatalog/OperatorInfoPopup";
import AddOperatorPopup from "../../components/operatorcatalog/AddOperatorPopup";
import InstalledOperatorItem from "../../components/operatorcatalog/InstalledOperatorItem";
import {
  fetchInstalledOperators,
  clearInstalledOperators,
  initiateFetchOperatorStatus,
} from "../operators/redux/actions";
import { AppConstants } from "../../constants/appconstants";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Dispatch } from "redux";
import { ClusterDetailsModel } from "../../models/Cluster.model";
import { Operator } from "../../models/Operator.model";

/* istanbul ignore next */
const useStyles = makeStyles((theme:Theme) => ({
  editIcon: {
    border: "3px dashed",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme?.transitions?.create("transform", {
      duration: theme?.transitions?.duration?.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardHeader: {
    padding: "0 !important",
  },
  cardHeaderOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  skeletonContainer: {
    padding: "10px",
  },
}));

interface Props extends PropsFromRedux {
  cluster:ClusterDetailsModel;
}

export const ClusterOperator = (props:Props) => {
  const {
    cluster,
    operatorCatalogs,
    fetchingOperators,
    clusterPackageStatus,
    operatorStatus,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [t] = useTranslation();
  const classes = useStyles();

  const [isOperatorInfoPopupOpen, setIsOperatorInfoPopupOpen] = useState(false);
  const [operatorToView, setOperatorToView] = useState<any>(null);

  const [isAddOperatorPopupOpen, setIsAddOperatorPopupOpen] = useState(false);

  const [isOlmPackageInstalled, setIsOlmPackageInstalled] = useState(false);

  const handleInstallOperator = () => {
    setIsAddOperatorPopupOpen(true);
  };

  const handleOperatorView = (operator:Operator) => {
    setOperatorToView(operator);
    setIsOperatorInfoPopupOpen(true);
  };

  const handleOperatorInfoPopupClose = () => {
    setIsOperatorInfoPopupOpen(false);
    setOperatorToView(null);
  };

  const handleAddOperatorPopupClose = () => {
    setIsAddOperatorPopupOpen(false);
  };

  useEffect(() => {
    props.fetchInstalledOperators(cluster.id);
    props.initiateFetchOperatorStatus(cluster.id);

    return () =>{
      props.clearInstalledOperators();
    } 
  }, []);

  useEffect(() => {
    if (clusterPackageStatus) {
      const olmPackage:any = Object.values(clusterPackageStatus).find(
        (pkg:any) => pkg.namespace === "olm"
      );

      if (olmPackage?.status === AppConstants.PackageStatus.Installed) {
        setIsOlmPackageInstalled(true);
      }
    }
  }, [clusterPackageStatus]);

  return (
    <>
      <Card
        className="m-t-20"
        data-test="operator-card-container"
        data-olmStatus={isOlmPackageInstalled ? "Installed" : ""}
        elevation={expanded ? 11 : 13}
      >
        <CardHeader
          // className={classes.cardHeader}
          title={
            <div className={classes.cardHeaderOptions}>
              <Typography variant="subtitle1" display="inline">
                {t("Cluster.ClusterOperator.clusterOperator")}
              </Typography>
              {expanded && isOlmPackageInstalled && (
                <IconButton>
                  <AddCircleOutlineOutlinedIcon
                    onClick={handleInstallOperator}
                    data-test="install-operator-button"
                  />
                </IconButton>
              )}
            </div>
          }
          // avatar={
          //   <IconButton
          //     className={clsx(classes.expand, {
          //       [classes.expandOpen]: expanded,
          //     })}
          //     onClick={() => {
          //       setExpanded(!expanded);
          //     }}
          //     data-test="expand-operator-card-button"
          //   >
          //     <ExpandMoreIcon />
          //   </IconButton>
          // }
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => {
                setExpanded(!expanded);
              }}
              data-test="expand-operator-card-button"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
          data-test="operator-card-header"
        />
        <Collapse in={expanded}>
          {!fetchingOperators ? (
            <>
              <CardContent>
                {isOlmPackageInstalled ? (
                  operatorCatalogs?.length ? (
                    <Grid
                      container
                      spacing={1}
                      data-test="installed-operators-list"
                    >
                      {operatorCatalogs.map((operator:Operator, index:number) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={index}
                          data-test="installed-operator-item"
                        >
                          <InstalledOperatorItem
                            operator={operator}
                            handleOperatorView={handleOperatorView}
                            operatorStatus={
                              operatorStatus &&
                              operatorStatus[
                                operator.operator_details.operator.name
                              ]
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <div
                      className="cluster-info-message"
                      data-test="no-installed-operators-message"
                    >
                      <ErrorOutlineIcon fontSize="small" />
                      <Typography variant="caption" className="message">
                        {t("Cluster.ClusterOperator.noInstallOperator")}
                      </Typography>
                    </div>
                  )
                ) : clusterPackageStatus &&
                  Object.values(clusterPackageStatus).find(
                    (pkg:any) => pkg.namespace === "olm"
                  ) ? (
                  <div
                    className="cluster-info-message"
                    data-test="olm-not-installed-message"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                    <Typography variant="caption" className="message">
                      {t("Cluster.ClusterOperator.noOlmPackage")}
                    </Typography>
                  </div>
                ) : (
                  <div
                    className="cluster-info-message"
                    data-test="fetching-olm-status-message"
                  >
                    <CircularProgress
                      size={16}
                      thickness={4.5}
                      color="inherit"
                    />
                    <Typography variant="caption" className="message">
                      {t("Cluster.ClusterOperator.fetchingOlmStatus")}
                    </Typography>
                  </div>
                )}
              </CardContent>
            </>
          ) : (
            <Grid
              container
              spacing={1}
              alignItems="center"
              className={classes.skeletonContainer}
              data-test="loader-skeleton"
            >
              {[0, 1, 2, 3, 4, 5, 6, 7].map(idx => {
                return (
                  <Grid item xs={12} sm={6} md={3} xl={3} key={idx}>
                    <Paper>
                      <SkeletonTheme height={200}>
                        <Skeleton width={150} height={118} />
                        <br />
                        <br />

                        <Skeleton
                          height={10}
                          style={{ marginBottom: 6 }}
                          width="80%"
                        />
                        <Skeleton  height={20} width="100%" />
                      </SkeletonTheme>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Collapse>
      </Card>

      {operatorToView && (
        <OperatorInfoPopup
          open={isOperatorInfoPopupOpen}
          handleClose={handleOperatorInfoPopupClose}
          operator={operatorToView}
          operatorStatus={
            operatorToView &&
            operatorStatus &&
            operatorStatus[operatorToView.operator_details.operator.name]
          }
          cluster={cluster}
        />
      )}
      <AddOperatorPopup
        open={isAddOperatorPopupOpen}
        handleClose={handleAddOperatorPopupClose}
        operator={operatorToView}
        cluster={cluster}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  operatorCatalogs: state.OperatorReducer.operatorCatalogs,
  fetchingOperators: state.OperatorReducer.fetchingOperators,
  clusterPackageStatus: state.ClusterReducer.clusterPackageStatus,
  operatorStatus: state.OperatorReducer.operatorStatus,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    fetchInstalledOperators: (clusterId:number) =>
      dispatch(fetchInstalledOperators(clusterId)),
    clearInstalledOperators: () => dispatch(clearInstalledOperators()),
    initiateFetchOperatorStatus: (clusterId:number) =>
      dispatch(initiateFetchOperatorStatus(clusterId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClusterOperator);