import { Divider, Grid, Menu, MenuItem } from "@material-ui/core";
//import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ReceiptIcon from "@material-ui/icons/Receipt";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import ConfirmDeletePopup from "../../../components/confirmdeletepopup/ConfirmDeletePopup";
import BackdropLoader from "../../../components/loader/BackdropLoader";
import TabsSkeleton from "../../../components/skeletons/TabsSkeleton";
import {
  clearProjectDetails,
  deleteProjectApiCall,
  fetchAppListByPID,
  fetchProjectDetails,
  fetchProjectRole,
  setCurrentProject,
  updateBreadcrumb,
  toggleProjectActivation
} from "../redux/actions";
import { switchOrg } from "../../organization/redux/actions";
import ActivityTab from "./activitytab/ActivityTab";
import DashBoardTab from "./dashboardtab/DashBoardTab";
import InsightTab from "./insighttab/InsightTab";
import LoadbalancerTab from "./loadbalancertab/LoadBalancerTab";
import "./ProjectInfo.css";
import SettingsTab from "./settingstab/SettingsTab";
import UserTab from "./usertab/UserTab";
import { ProjectSettingTabs } from "../../../constants/environmentTabs";
import { DateHandler } from "../../../components/dateHandler/DateHandler";
import NoContentImage from "../../../components/nocontentimagecontainer/NoContentImage";
import paths from "../../../constants/paths";
import { LabelHandler } from "../../../components/labelHandler/LabelHandler";
import { TabPanel } from "../../../components/tabpanel/TabPanel";
import CustomButton from "../../../components/custombutton/CustomButton";
import { Dispatch } from "redux";
import { WithStyles } from "@material-ui/styles";
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { BreadcrumbModel } from "../../../models/Common.model";
import { TitleHandler } from "../../../components/titleHandler/TitleHandler";
import { RocketLaunchIcon } from "../../../helpers/customIcon";
import { CustomColors } from "../../../constants/enums";
/* istanbul ignore next */
const useStyles = () => ({
  tabRoot: {
    minWidth: 64,
  },
});

interface MatchParams {
  id: string;
}


interface Props extends PropsFromRedux, WithTranslation, WithStyles<typeof useStyles>, RouteComponentProps<MatchParams> {
  toggleActivationProject: boolean
}

type State = {
  value: number,
  projectId: number,
  innerTab: number,
  isPopupOpened:boolean,
  isToggleActivatitonPopupOpen:boolean,
  anchorEl: any
}

function a11yProps(index: number | undefined) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export class ProjectInfo extends Component<Props, State, MatchParams> {
  dummyData = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      value: 0,
      projectId: parseInt(this.props.match.params.id),
      innerTab: 0,
      isPopupOpened:false,
      isToggleActivatitonPopupOpen:false,
      anchorEl: null
    };
  }

  updateInnerTab = (value: number) => {
    this.setState({ innerTab: value });
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  deleteProjectDetailsHandler = () => {
    this.handleMenuClose();
    this.setState({
      isPopupOpened: true,
    });
  };
  toggleActivation=()=>{
    this.handleMenuClose();
    this.setState({
      isToggleActivatitonPopupOpen:true
    })
  }

  handleDisAgreeHandler = () => {
    this.setState({
      isToggleActivatitonPopupOpen:false,
      isPopupOpened: false,
    })

  };

  handleAgreeHandler =(action: number)=> () => {
    if(action===1){
      this.props.deleteProjectApiCallAction(
        parseInt(this.props.match.params.id),
        this.props.history
      );
      this.setState({
        isPopupOpened: false,
      });
      return
    }

    if(action===2){
      this.props.toggleProjectActivation(parseInt(this.props.match.params.id),false)
    }
    if(action===3){
      this.props.toggleProjectActivation(parseInt(this.props.match.params.id),true)
    }

    this.setState({
      isToggleActivatitonPopupOpen: false,
    });
  };

  componentDidMount() {
    this.props.fetchProjectDetails(this.state.projectId);
    // this.props.fetchUserListInProjectAction(this.state.projectId);
    // this.props.fetchRolesAction();
    this.props.setCurrentProject(this.state.projectId);
    this.props.fetchProjectRole(this.state.projectId);
    //this.getTabIndexbyName();
    if (this.props.projectDetails?.id) {
      const breadcrumbData = [
        {
          name: this.props.projectDetails.name,
          path: "/project/" + this.props.projectDetails.id,
        },
      ];
      this.props.updateBreadcrumb(breadcrumbData);
    }
  }

  componentWillUnmount() {
    this.props.clearProjectDetails();
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //     debugger;
  //    if(nextProps.match.params.id !== prevState.projectId){
  //        return {
  //         projectId : nextProps.match.params.id
  //        }
  //    }
  // }

  // componentDidUpdate(prevProps,prevState){
  //     if(this.props.match.params.id !== prevProps.match.params.id){
  //         this.props.fetchProjectDetails(this.state.projectId);
  //         this.props.fetchAppListByPID(this.state.projectId);
  //         this.props.fetchUserListInProjectAction(this.state.projectId);
  //     }
  // }

  /* istanbul ignore next */
  getTabIndexbyName = () => {
    const newHash = this.props.match.params.id.split("#");
    const newTotalHash = this.props.location.hash
      ? this.props.location.hash
      : newHash && newHash.length > 1
      ? "#" + newHash[1]
      : "";
    if (newTotalHash) {
      const splitHash = newTotalHash.split("_");
      if (splitHash.length > 1) {
        const subIndex = ProjectSettingTabs.indexOf(splitHash[1]);
        this.updateInnerTab(subIndex > -1 ? subIndex : 0);
      }
      const tabPanel = document.getElementsByName(splitHash[0]);
      if (tabPanel && tabPanel.length > 0) {
        const id = tabPanel[0].id;
        const index = id.split("-").pop();
        if (index && parseInt(index) > -1) this.handleChange(null, parseInt(index));
      }
    }
  };

  switchOrgCallback = () => {
    this.props.fetchProjectRole(this.state.projectId);
  };

  UNSAFE_componentWillReceiveProps = (newProps: Props) => {
    if (
      this.props.match.params.id.split("#")[0] !==
      newProps.match.params.id.split("#")[0]
    ) {
      var newpId = newProps.match.params.id.split("#")[0];
      var newPId = parseInt(newpId);
      this.setState({
        projectId: newPId,
      });
      this.props.fetchProjectDetails(newPId);
      this.props.fetchAppListByPID(newPId);
      // this.props.fetchUserListInProjectAction(newPId);
      this.props.setCurrentProject(newPId);
      this.props.fetchProjectRole(newPId);
    }

    if (newProps.projectDetails?.id > 0) {
      if (
        !this.props.projectDetails.id ||
        this.props.projectDetails.id !== newProps.projectDetails.id
      ) {
        const breadcrumbData = [
          {
            name: newProps.projectDetails.name,
            path: "/project/" + newProps.projectDetails.id,
          },
        ];
        this.props.updateBreadcrumb(breadcrumbData);
        const org_id = newProps.projectDetails?.organization_id;
        if (org_id) {
          if (
            !this.props.currentOrganization || this.props.currentOrganization.id.toString() !== org_id
          ) {
            this.props.switchOrg(org_id, null, null, 0, this.switchOrgCallback);
          }
        } else if (this.props.currentOrganization?.id) {
          this.props.switchOrg(0, null, null, 0, this.switchOrgCallback);
        }

        setTimeout(this.getTabIndexbyName, 200);
      }
      if (
        this.props.projectDetails &&
        this.props.projectDetails.dedicated_lb !==
          newProps.projectDetails.dedicated_lb
      ) {
        setTimeout(this.getTabIndexbyName, 200);
      }
    }
  };

  handleChange = (event: any, newValue: any) => {
    this.setState({
      value: newValue,
    });

    if (event) {
      const tabPanelObj = document.getElementById(
        "simple-tabpanel-" + newValue
      );
      if (tabPanelObj) {
        const name = tabPanelObj.getAttribute("name");
        if (name) {
          this.props.history.push({
            pathname:
              paths.PROJECTINFO.replace(":id", this.state.projectId.toString()) + name,
          });
        }
      }
    }
  };

  isOwnerOrAdmin = ( role: string) => {
    let authorized = false
    //  if(source && state){
    //     if(isStopped(source, state)){
    //        return false
    //     }
    //  }
    if (role === 'Admin' || role === 'Owner' || role === "Org Admin") {
       authorized = true
    }
 
    return authorized
 }
 
  render() {
    
    var { projectDetails, classes, projectRole, t } = this.props;
    const { isPopupOpened } = this.state;
    const tabIndexes = this.props.projectDetails
      ? this.props.projectDetails.dedicated_lb
        ? {
            Apps: 0,
            Overview: 1,
            Activity: 2,
            Permission: 3,
            Loadbalancer: 4,
            Settings: 5,
          }
        : {
            Apps: 0,
            Overview: 1,
            Activity: 2,
            Permission: 3,
            Settings: 4,
          }
      : {};
    return (
      <div data-test="projectInfoContainer">
        {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link color="inherit" href="/" onClick={handleClick}>
                        Dashboard
                         </Link>
                    <Link color="textPrimary" href="/" onClick={handleClick}>
                        All Projects
                    </Link>
                </Breadcrumbs> */}
        {this.props.isDataNotFound && (
          <Grid
            container
            justify="center"
            alignItems="center"
            className="notFoundGrid"
            data-test="noDataFound"
          >
            <NoContentImage
              message={t("Projects.ProjectInfo.projectNotFound")}
              alt="No Apps"
              type={"404"}
            />
          </Grid>
        )}
        {!this.props.isDataNotFound && (
          <div data-test="dataFoundContainer">
            <TitleHandler 
            image={
                      projectDetails && projectDetails.name ? (
                      projectDetails && projectDetails.image ? (
                        <div >
                          <img
                            src={projectDetails.image}
                            alt="Project icon"
                            height={25}
                            data-test="projectImage"
                          />
                        </div>
                      ) : (
                        <DashboardIcon
                        
                          color="primary"
                          height={25}
                          fontSize="small"
                        />
                      )
                    ) : (
                      <Skeleton height={25} width={25} data-test="skeleton" />
                    )
                  }
            title={projectDetails && projectDetails.name ? (projectDetails.name) : (<Skeleton width={100} />)}
            actionItem={  
                  this.isOwnerOrAdmin(projectRole.name) && (
                          <>
                            <span className="marginBtn">
                              <Button
                                variant="contained"
                                color="primary"
                                endIcon={<ExpandMoreIcon />}
                                disableElevation
                                disableFocusRipple
                                disableTouchRipple
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(e) => this.handleMenuClick(e)}
                                data-test="projectActionsButton"
                              >
                                {t("Projects.ProjectInfo.actionsLabel")}
                              </Button>
                            </span>
                            <Menu
                              id="simple-menu"
                              disableScrollLock={true}
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={(e) => this.handleMenuClose()}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              data-test="menu"
                            >
                              <MenuItem
                                onClick={this.deleteProjectDetailsHandler}
                                data-test="projectDeleteAction"
                              >
                                {t("Projects.ProjectInfo.deleteLabel")}
                              </MenuItem>
                            
                                {projectDetails && projectDetails.active? <MenuItem
                                onClick={this.toggleActivation}
                                data-test="projectDeactivatetAction"
                                                        >
                              {t("Projects.ProjectInfo.deactivateLabel")}
                                                        </MenuItem>: <MenuItem
                                onClick={this.toggleActivation}
                                data-test="projectActivationAction"
                                                                    >
                              {t("Projects.ProjectInfo.activateLabel")}
                                                                    </MenuItem>
                              }
    
                            </Menu>
                          </>)
                        }
            />
            <Grid
              container
              // variant="outlined"
              // className={classes.header}
              data-test="projectDetailsContainer"
            >
              <Grid item md={12} xs={12}>
                <Grid container direction="row" spacing={2} alignItems="center">
                  {/* <Grid item>
                    {projectDetails && projectDetails.name ? (
                      projectDetails && projectDetails.image ? (
                        <div >
                          <img
                            src={projectDetails.image}
                            alt="Project icon"
                            height={25}
                            data-test="projectImage"
                          />
                        </div>
                      ) : (
                          <img
                            src={/images/default}
                            alt="Default Project Icon"
                            height={60}
                            width={60}
                            className="projecticonImg"
                        />
                        <DashboardIcon
                        
                          color="primary"
                          height={25}
                          fontSize="small"
                        />
                      )
                    ) : (
                      <Skeleton height={25} width={25} data-test="skeleton" />
                    )}
                  </Grid> */}
                  {/* <Grid item xs>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="h4"
                          className={`projectTitle`}                         
                          data-test="projectName"
                        >
                          {projectDetails && projectDetails.name ? (
                            projectDetails.name
                          ) : (
                            <Skeleton width={100} />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  {this.isOwnerOrAdmin(projectRole.name) && (
                    <Grid item>
                      <React.Fragment>
                        {isPopupOpened && (
                          <ConfirmDeletePopup
                            open={isPopupOpened}
                            handleAgree={this.handleAgreeHandler(1)}
                            handleDisAgree={this.handleDisAgreeHandler}
                            message={`This action will permanently delete all the data. Please type "${projectDetails.name}" to terminate the Project : ${projectDetails.name}`}
                            // yesText={t("Projects.ProjectInfo.yesLabel")}
                            // noText={t("Projects.ProjectInfo.noLabel")}
                            toMatchName={projectDetails.name}
                            toDeleteModule="project"
                            loading={this.props.deletingProject}
                            data-test="delete-popup"
                          />
                        )}
                          { projectDetails && projectDetails.active &&  (
                          <ConfirmDeletePopup
                            open={this.state.isToggleActivatitonPopupOpen || this.props.toggleProjectActivatitonCallStarted   }
                            handleAgree={this.handleAgreeHandler(2)}
                            handleDisAgree={this.handleDisAgreeHandler}
                            message={
                              <Typography variant='subtitle1'>
                                This action will <span style={{fontWeight:'bold'}}>deactivate</span> project <span style={{fontWeight:'bold'}}>{projectDetails.name}</span>. Please type &#34;{projectDetails.name}&#34; to  Deactivate : <span style={{fontWeight:'bold'}}>{projectDetails.name}</span>
                              </Typography>
                              }
                            // yesText={t("Projects.ProjectInfo.yesLabel")}
                            // noText={t("Projects.ProjectInfo.noLabel")}
                            toMatchName={projectDetails.name}
                            toDeleteModule="project"
                            loading={this.props.toggleProjectActivatitonCallStarted} // bring async data from redux here
                            action='deactivate'
                            data-test="deactivate-popup"
                          />
                        ) }
                        {projectDetails && !projectDetails.active &&  <ConfirmDeletePopup
                        open={this.state.isToggleActivatitonPopupOpen ||this.props.toggleProjectActivatitonCallStarted}
                        handleAgree={this.handleAgreeHandler(3)}
                        handleDisAgree={this.handleDisAgreeHandler}
                        message={
                          <Typography variant='subtitle1'>
                          This action will <span style={{fontWeight:'bold'}}>activate</span> project <span style={{fontWeight:'bold'}}>{projectDetails.name}</span>. Please type &#34;{projectDetails.name}&#34; to  Enable : <span style={{fontWeight:'bold'}}>{projectDetails.name}</span>
                          </Typography> 
                        }
                        
                        // yesText={t("Projects.ProjectInfo.yesLabel")}
                        // noText={t("Projects.ProjectInfo.noLabel")}
                        toMatchName={projectDetails.name}
                        toDeleteModule="project"
                        loading={this.props.toggleProjectActivatitonCallStarted } // bring async data from redux here
                        action='activate'
                        data-test="activate-popup"
                                                    />}
                          {/* { !projectDetails.active && (
                        
                        )} */}
                        {this.props.deletingProject && (
                          <BackdropLoader
                            message="Deleting Your Project"
                            data-test="backdrop-loader"
                          />
                        )}
                         {this.props.toggleActivationProject && (
                          <BackdropLoader
                            message="Deleting Your Project"
                            data-test="backdrop-loader"
                          />
                        )}
                        {!projectDetails && (
                          <BackdropLoader message="" data-test="backdrop" />
                        )}
                      </React.Fragment>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* <span>{ projectDetails && projectDetails.subscription ? projectDetails.subscription.cores : "-" } Cores / </span>
                        <Typography color="textPrimary" variant="subtitle1" className='projectTitle'>
                            {projectDetails && projectDetails.name ? projectDetails.name : "-  -  -  -   -  -" }
                        </Typography>
                        <span>
                            <MoreVertIcon disableElevation disableFocusRipple disableTouchRipple
                                    aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleMenuClick(e)}>
                            </MoreVertIcon>
                              <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                open={Boolean(this.state.anchorEl)}
                                onClose={e => this.handleMenuClose()}
                                //className={classes.profileMenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                              >
                                <MenuItem onClick={ this.deleteProjectDetailsHandler}>Delete this Projrct</MenuItem>
                              </Menu>
                        </span>
         
                <ConfirmActionPopup open={ isPopupOpened } handleAgree={ this.handleAgreeHandler } handleDisAgree={ this.handleDisAgreeHandler } message='Are you sure you want to remove this project?' yesText='Yes' noText='No'/>
             
                        {/* <span className='projectSubDetails'>
                            <span>{ projectDetails && projectDetails.subscription ? projectDetails.subscription.cores : "-" } Cores / </span>
                            <span>{ projectDetails && projectDetails.subscription ? projectDetails.subscription.disk_space : "-" }GB SSD / </span>
                            <span>{ projectDetails && projectDetails.subscription ? projectDetails.subscription.data_transfer : "-" } TB Data transfer / </span>
                            <span>{ projectDetails && projectDetails.subscription ? projectDetails.subscription.memory : "-" } GB RAM / </span>
                            <span>0 Apps </span> */}
            <Grid
              container
              className={`appIconsTop`}
              spacing={3}
              data-test="projectSubDetails"
          
            >
              <Grid item className="topgridalign">
                <LabelHandler
                  icon={<RocketLaunchIcon  style={{color: CustomColors.label.Dark}}/>}
                  iconTooltip={"Subscription Package"}
                  label={projectDetails?.subscription?.name }
                  data-test="subscription-name"
                  labelType="Success"
                />
              </Grid>
              <Grid item className="topgridalign">
                <LabelHandler
                  icon={<PersonOutlineOutlinedIcon style={{color: CustomColors.label.Dark}} />}
                  iconTooltip={t("Projects.ProjectInfo.createdBy")}
                  label={
                    projectDetails?.user?.first_name
                      ? `${projectDetails?.user?.first_name} ${projectDetails?.user?.last_name}`
                      : ""
                  }
                  data-test="project-username"
                  labelType="Info"
                />
              </Grid>
              <Grid item className="topgridalign">
                <DateHandler
                  date={projectDetails?.createdat}
                  // inProjectInfo
                  icon={<ScheduleIcon className="svgicon" style={{color: CustomColors.label.Dark}}/>}
                  labelType="Secondary"
                />
              </Grid>
            </Grid>
            <Divider />
          {projectDetails && !projectDetails.active? <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <NoContentImage
              // message={`This Project is Disabled .\n ${!this.isOwnerOrAdmin(projectRole.name)?"You do not have permission to enable this project. Please contact admin or owner of this project.":''}`}
             message={<div>
              <Typography variant='subtitle1'>
              This Project is Deactivated.
              </Typography>
              {!this.isOwnerOrAdmin(projectRole.name) && <Typography variant='subtitle1'>
                You do not have permission to activate this project. Please contact admin or owner of this project.
                                                         </Typography>
                }
                      </div>}
              alt="No Apps"
              type={"project"}
            />
             <span style={{display:'flex',justifyContent:'center'}}>
             <CustomButton
                   label= {t("Projects.ProjectInfo.activateLabel")}
                   onClick={this.toggleActivation}
                   data-test="createButton"
                   disabled={!this.isOwnerOrAdmin(projectRole.name)}
             />
             </span>
                                   </div>:<div className="tabsContainer" data-test="tabsContainer">
              {this.props.projectDetails && this.props.projectDetails.name ? (
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  aria-label="simple tabs example"
                  indicatorColor="primary"
                  variant="scrollable"
                  data-test="tabs"
                >
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t("Projects.ProjectInfo.appsLabel")}
                    data-test="appsTab"
                    {...a11yProps(tabIndexes.Apps)}
                  />
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t("Projects.ProjectInfo.overviewLabel")}
                    data-test="overviewTab"
                    {...a11yProps(tabIndexes.Overview)}
                  />
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t("Projects.ProjectInfo.activityLabel")}
                    data-test="activityTab"
                    {...a11yProps(tabIndexes.Activity)}
                  />
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t("Projects.ProjectInfo.permissionLabel")}
                    data-test="permissionsTab"
                    {...a11yProps(tabIndexes.Permission)}
                  />
                  {this.props.projectDetails.dedicated_lb && (
                    <Tab
                      classes={{ root: classes.tabRoot }}
                      data-test="loadbalancerTab"
                      label={t("Projects.ProjectInfo.loadBalancerLabel")}
                      {...a11yProps(tabIndexes.Loadbalancer)}
                    />
                  )}
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    data-test="settingsTab"
                    label={t("Projects.ProjectInfo.settingsLabel")}
                    {...a11yProps(tabIndexes.Settings)}
                  />
                </Tabs>
              ) : (
                <TabsSkeleton />
              )}
              <div className="horizontalLine m-b-20" />
              <TabPanel
                className="removePadding"
                value={this.state.value}
                index={tabIndexes.Apps}
                name="#apps"
                tabPanel="simple-tabpanel"
              >
                <DashBoardTab
                  projectId={this.state.projectId}
                  //userData={projectDetails?.user}
                  history={this.props.history}
                />
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={tabIndexes.Overview}
                name="#overview"
                tabPanel="simple-tabpanel"
              >
                <InsightTab projectId={this.state.projectId} />
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={tabIndexes.Activity}
                name="#activity"
                tabPanel="simple-tabpanel"
              >
                <ActivityTab projectId={this.state.projectId} />
              </TabPanel>

              <TabPanel
                value={this.state.value}
                index={tabIndexes.Permission}
                name="#users"
                tabPanel="simple-tabpanel"
              >
                <UserTab
                  projectId={this.state.projectId}
                  history={this.props.history}
                />
              </TabPanel>
              {this.props.projectDetails?.dedicated_lb && (
                <TabPanel
                  value={this.state.value}
                  index={tabIndexes.Loadbalancer}
                  name="#loadbalancer"
                  tabPanel="simple-tabpanel"
                >
                  <LoadbalancerTab />
                </TabPanel>
              )}
              <TabPanel
                value={this.state.value}
                index={tabIndexes.Settings}
                name="#settings"
                tabPanel="simple-tabpanel"
              >
                <SettingsTab
                  innerTab={this.state.innerTab}
                  updateInnerTab={this.updateInnerTab}
                  history={this.props.history}
                  data-test="innerTab"
                />
              </TabPanel>
                                          </div> }  
          </div>
        )}
        {this.props.fetchingProjectDetails && (
          <BackdropLoader
            message={t("Projects.ProjectInfo.fetchingProjectsMessage")}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  projectDetails: state.ProjectReducer.projectDetails,
  isDataNotFound: state.ProjectReducer.isDataNotFound,
  projectRole: state.ProjectReducer.projectRole,
  deletingProject: state.ProjectReducer.deletingProject,
  fetchingProjectDetails: state.ProjectReducer.fetchingProjectDetails,
  currentOrganization: state.AuthReducer.currentOrganization,
  toggleProjectActivatitonCallStarted :  state.ProjectReducer.toggleProjectActivatitonCallStarted

});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    fetchProjectDetails: (payload: number) => dispatch(fetchProjectDetails(payload)),
    fetchAppListByPID: (payload: number) => dispatch(fetchAppListByPID(payload)),
    //fetchUserListInProjectAction : payload => dispatch(fetchUserListInProject(payload)),
    deleteProjectApiCallAction: (id: number, history: History) =>
      dispatch(deleteProjectApiCall(id, history)),
    toggleProjectActivation:(id: number,is_Operational: boolean) =>
      dispatch(toggleProjectActivation(id,is_Operational)),
    //fetchRolesAction : (payload) => dispatch(fetchRoles(payload)),
    updateBreadcrumb: (breadcrumbData: BreadcrumbModel[]) =>
      dispatch(updateBreadcrumb(breadcrumbData)),
    setCurrentProject: (id: number) => dispatch(setCurrentProject(id)),
    fetchProjectRole: (id: number) => dispatch(fetchProjectRole(id)),
    clearProjectDetails: () => dispatch(clearProjectDetails()),
    switchOrg: (id: number, history: null, path: null, source: number, dependencyCallback:()=>void) =>
      dispatch(switchOrg(id, history, path, source, dependencyCallback)),
  };
};

// export default connect(
//   mapStateToProps,
//   mapDispatchtoProps
// )(withStyles(useStyles)(withTranslation()(ProjectInfo)));

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(ProjectInfo)));