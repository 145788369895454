import React, { useEffect, useState , ChangeEvent } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Theme, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { updateIPWhitelisting } from "../../redux/actions";
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import { useTranslation } from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { GeneralStyledTableCell  as StyledTableCell } from "../../../../components/styledtabelcell/StyledTabelCell";
import Alert from '@material-ui/lab/Alert';
import { CustomColors } from "../../../../constants/enums";
import palette from "../../../../theme/palette";

const useStyles = makeStyles((theme: Theme) => ({
  // headerWrap: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   paddingLeft: 8,
  //   paddingRight: 8,
  // },
  onEditActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
  },
  topmargin: {
    marginTop: 44,
  },
  subHeadingText:{
    color: palette.text.disabled
  },
  infoBackground:{
    borderRadius: '8px', 
    backgroundColor: CustomColors.Info.Lighter
  },
  infoText:{
      color: CustomColors.Info.Darker
  },
  infoPaper: {
      margin: "20px 20px",
  },
  colWidth:{
    width: '50%'
  }
  }));

interface Props extends PropsFromRedux {}

export const IpWhitelisting = (props: Props) => {
  const classes = useStyles();
  const [t] = useTranslation()
  const [chips, setChips] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const isValues = (props.environmentDetails.whitelisted_ips || "").split(",");

  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/[0-9]+)?$/;

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newChips = [...chips];
    newChips[index] = value;
    setChips(newChips);
    const newErrors = [...errors];
    newErrors[index] = ipRegex.test(value) ? "" : "Invalid IP address format.";
    setErrors(newErrors);
    if (index === chips.length - 1 && ipRegex.test(value)) {
      setChips([...newChips]);
      setErrors([...newErrors, ""]);
    }
    console.log(chips.length);
    
  };
  

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     const newChips = chipInput
  //       .split(",")
  //       .map((part) => part.trim())
  //       .filter((part) => part.length > 0);
  //     const uniqueNewChips = [...new Set(newChips)];
  //     const invalidChips = uniqueNewChips.filter((chip) => !ipRegex.test(chip));
  //     if (invalidChips.length > 0) {
  //       setIsError("Invalid IP address format.");
  //       return;
  //     }

  //     const uniqueChips = [...new Set([...chips, ...uniqueNewChips])];
  //     setChips(uniqueChips);
  //     setChipInput("");
  //     setIsError("");
  //   }
  // };

  const handleDeleteChip = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
  };

  const handleEdit = () => {
    setIsEdit(true);
  }

  const handleSubmit = () => {
    const filteredChips = chips.filter(chip => chip.trim() !== "");
    setIsEdit(false);
    const id = props.environmentDetails.id;
    const jsonBody = filteredChips.join(',');
    props.updateIPWhitelisting(id, jsonBody);
    setChips([]);
  };
  

  useEffect(() => {
    if (!props.isIPWhitelisting && props.environmentDetails.whitelisted_ips) {
      const values = props.environmentDetails.whitelisted_ips.split(",");
      setChips(values);
    }
  }, [props.isIPWhitelisting, props.environmentDetails]);
  

  const arraysEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  };

  const isChipsEqualToProps = arraysEqual(chips, isValues);

  const handleCancel = () => {
    const ips = props.environmentDetails.whitelisted_ips;
    if (ips) { 
        const values = ips.split(",");
        setChips(values);
    } else {
        setChips([]); 
    }
    setIsEdit(false);
}
const handleAddNew = () => {
  setChips([...chips, ""])
}

const isEmpty = (chips.length === 0 && (!props.environmentDetails.whitelisted_ips || props.environmentDetails.whitelisted_ips.length === 0));

const hasEmptyChips = chips.some(chip => chip.trim() === "");

const hasErrors = errors.some(error => error !== "");

  return (
    <div data-test="Ipwhitelisting-container">
      {/* {!props.isIPWhitelisting && (
        <div>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" data-test="parent-container">
            <Grid item>
              <Typography variant="h3">{t('Environment.SettingsTab.ipwhitelisting')}</Typography>
              <Typography className={classes.subTitle}>
                {t('Environment.SettingsTab.subtitletag')}
              </Typography>
            </Grid>
            <Box>
              <Button
                className="m-l-10"
                color="primary"
                variant="contained"
                size="medium"
                onClick={handleEdit}
              >
                {t('Environment.SettingsTab.edit')}
              </Button>
            </Box>
          </Grid>
          <div style={{ marginTop: 20 }}>
            <Box>
              <Typography variant="h5">{t('Environment.SettingsTab.iplist')}</Typography>
              <div
                style={{ marginTop: 10, minHeight: 200 }}
                className="react-multi-email"
                data-test="ipinput-container"
              >
                {isEdit && (
                  <input
                    ref={(input) => input && input.focus()}
                    value={chipInput}
                    placeholder="Enter IP address"
                    onKeyDown={handleKeyPress}
                    onChange={handleInputChange}
                    data-test="ip-input"
                  />
                )}
                {isError && (
                  <p className="error" data-test="error-message">
                    {isError}
                  </p>
                )}
                {chips.length !== 0 ? (
                  chips.map((item) => (
                    <div className={classes.tagItem} key={item} data-test="ip-list-container" style={{ marginTop: 10 }}>
                      {item}
                      {isEdit && (
                        <button
                          type="button"
                          className={classes.button}
                          onClick={() => handleDeleteChip(item)}
                          data-test="handleDelete"
                        >
                          &times;
                        </button>
                      )}
                    </div>
                  ))
                  ) : (
                    !isEdit && (
                      <Typography>{t('Environment.SettingsTab.nolistfound')}</Typography>
                    )
                )}
              </div>
            </Box>
            <Box display="flex" justifyContent="end" marginTop={5}>
              <Button
                className="m-l-10"
                color="primary"
                variant="contained"
                size="medium"
                disabled={isChipsEqualToProps || !isEdit}
                onClick={handleSubmit}
              >
                {t('Environment.SettingsTab.submit')}
              </Button>
            </Box>
          </div>
        </div>
      )} */}
      {!props.isIPWhitelisting && (<div>
        {/* <Grid container spacing={2} justifyContent="space-between" alignItems="center" data-test="parent-container">
            <Grid item>
              <Typography variant="h3" data-test="title">{t('Environment.SettingsTab.ipwhitelisting')}</Typography>
              <Typography className={classes.subTitle} data-test="sub-title">{t('Environment.SettingsTab.subtitletag')}</Typography>
            </Grid>
        </Grid> */}
        <Card className="network">
          <CardHeader
            title={<Typography variant="h6">{t('Environment.SettingsTab.ipaddress')}</Typography>}
            data-test="env-network-cardHeader"
            subheader={<Typography variant="caption" className={classes.subHeadingText}>{props.environmentDetails.whitelisted_ips?.length === 0 ? t('Environment.SettingsTab.ipAddressSubTitle1') : t('Environment.SettingsTab.ipAddressSubTitle2')}</Typography>}
            action={
              <>
                {!isEdit ? (
                      <>
                        <IconButton onClick={handleEdit} color="primary" data-test="enable-edit">
                          <EditIcon />
                        </IconButton>
                      </>
                  ) : null}
                  {isEdit ? (
                    <div className={classes.onEditActions}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation={true}
                        disabled={isChipsEqualToProps || isEmpty || hasErrors || hasEmptyChips}
                        onClick={handleSubmit}
                        data-test="update-button"
                      >
                        {t('Environment.SettingsTab.submit')}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation={true}
                        onClick={handleCancel}
                        data-test="cancel-button"
                      >
                        {t('Environment.SettingsTab.cancel')}
                      </Button>
                    </div>
                  ) : null}
              </>
            }
          />
          <Divider/>
          <CardContent>
            {isEdit && (
              <Box display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={handleAddNew}
                  data-test="add-new-row"
                >
                 {t('Environment.SettingsTab.addiplist')}
                </Button>
            </Box>)}
            {chips.length === 0 && !isEdit  ?
                (
                  <Grid container justifyContent="center" className="m-t-10">
                    <Grid item xs={12} sm={11}>
                      <Paper elevation={8}>
                        <Alert severity="info" className={classes.infoBackground}>
                            <Typography variant='body2' className={classes.infoText}>{t("Environment.SettingsTab.ipNoData")}</Typography>
                        </Alert>
                      </Paper>
                    </Grid>
                  </Grid>
                ) :
                (
                  <TableContainer className={classes.topmargin}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className={classes.colWidth}>
                            {t('Environment.SettingsTab.iplist')}
                          </StyledTableCell>
                          {isEdit && (
                            <StyledTableCell className={classes.colWidth}>
                              {t("Environment.Generals.actions")}
                            </StyledTableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chips.length > 0 &&
                          chips.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell className={classes.colWidth}>
                                {isEdit ? (
                                  <TextField
                                    data-test="env-ip-key"
                                    value={item}
                                    variant="outlined"
                                    onChange={(e:any ) => handleInputChange(index, e)}
                                    error={!!errors[index]}
                                    helperText={errors[index]}
                                    style={{width: '60%'}}
                                  />
                                ) : (
                                  <Typography>{item}</Typography>
                                )}
                              </StyledTableCell>
                              {isEdit && (
                                <StyledTableCell className={classes.colWidth}>
                                  <div>
                                    <CloseIcon
                                      onClick={() => handleDeleteChip(item)}
                                      data-test="remove-ip-from-list"
                                    />
                                  </div>
                                </StyledTableCell>
                              )}
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) 
            }

          </CardContent>
        </Card>
      </div>)}
      
      {props.isIPWhitelisting && (<BackdropLoader message={"Updating IP..."} data-test="Updating-ipwhitelisting" />)}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  fetchingEnvVar: state.EnvironmentReducer.fetchingEnvVar,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  ipWhitelistingData: state.EnvironmentReducer.ipWhitelistingData,
  isIPWhitelisting: state.EnvironmentReducer.isIPWhitelisting,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateIPWhitelisting: (id: number, jsonBody: any) => dispatch(updateIPWhitelisting(id, jsonBody))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(IpWhitelisting);

type PropsFromRedux = ConnectedProps<typeof connector>;
