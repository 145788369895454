import React, { useState, useEffect } from "react";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Select,
	FormControl,
	MenuItem,
	Typography,
	IconButton,
	Tooltip,
	Collapse,
	Divider,
} from "@material-ui/core";
import clsx from "clsx";
import { connect, ConnectedProps } from "react-redux";
import { getDns, clearDnsInfo } from "../organization/redux/actions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { setupClusterDNS } from "./redux/actions";
import ConfirmActionPopup from "../../components/confirmactionpopup/ConfirmActionPopup";
import EditIcon from "@material-ui/icons/Edit";
import { getDateInStandardFormat } from "../../helpers/utils";
import AddDNSPopup from "../../components/adddnspopup/AddDNSPopup";
import { makeStyles } from "@material-ui/styles";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import KeyValueRow from "../../components/keyvaluerow/KeyValueRow";
import { useTranslation } from 'react-i18next'
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Dispatch } from "redux";
import { Theme } from "@material-ui/core/styles";
import { DNSModel } from "../../models/Organization.model";

/*istanbul ignore next */
const useStyles = makeStyles((theme: Theme) => ({
	editIcon: {
		border: "2px dashed"
	},
	expand: {
		transform: "rotate(0deg)",
		transition: theme?.transitions?.create("transform",{
			duration: theme?.transitions?.duration?.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	cardHeader:{
		padding: "0 !important"
	},
	dnsEditIcon: {
		marginTop: "8px !important",
		marginRight: "8px !important",
	},
}));

interface Props extends PropsFromRedux {
	dnsId: number,
	clusterId: number,
	mainClusterId: number,
	destroyed: boolean,
	packagesInstalled: boolean,
}

export const ClusterDNS = (props: Props) => {
	const [updateDnsPopup, setUpdateDnsPopup] = useState(false);
	const [selectedDns, setSelectedDns] = useState(0);
	const [editDns, setEditDns] = useState(false);
	const [openPopup, setOpenPopup] = useState(false);
	const [appliedDns, setAppliedDns] = useState<any>(null)
	const [expanded, setExpanded] = useState(false);
	const [t] = useTranslation()

	const classes = useStyles();

	useEffect(() => {
		props.getDns();
		return (() => {
			props.clearDnsInfo()
		})
	}, []);

	useEffect(() => {
		if(!props.dnsId){
			setExpanded(true)
		}
	}, [props.dnsId]);

	useEffect(() => {
		if (props.dnsList && props.dnsList.length > 0 && props.dnsId) {
			let _dns = props.dnsList.find((dns: DNSModel) => dns.id === props.dnsId);
			if (_dns) {
				setSelectedDns(_dns.id);
				setAppliedDns(_dns);
			}
		}
	}, [props.dnsList]);

	const updateDnsDisagreeHandler = () => {
		setUpdateDnsPopup(false);
	};

	const updateDnsCallback = () =>{
		setUpdateDnsPopup(false);
		setEditDns(false);
	}

	const updateDnsAgreeHandler = () => {
		if (props.packagesInstalled) return;
		let jsonBody: any = new FormData();
		jsonBody.append("dns_id", selectedDns);
		props.setupClusterDNS(
			props.clusterId,
			jsonBody,
			props.mainClusterId,
			updateDnsCallback
		);
	};

	const handleSetupClick = () => {
		setUpdateDnsPopup(true);
	};

	const handleDnsChange = (e: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedDns(e.target.value as number);
	};

	const handleEditIconClick = () => {
		setEditDns(!editDns);
	};

	const isSetupValid = () => {
		let valid = true;
		if (props.packagesInstalled) valid = false;
		else if (selectedDns === 0 || selectedDns === props.dnsId)
			valid = false;
		return !valid;
	};

	const handleCreateClick = () => {
		setOpenPopup(true);
	};

	const handleClosePopup = () => {
		setOpenPopup(false);
	};

	return (
		<div data-test="main-container">
			<Card className="m-t-20" elevation={expanded ? 11 : 13}>
				<CardHeader
				data-test="card-header"
				// className={classes.cardHeader}
					title={
						<Typography variant="subtitle1" display="inline">
							{t('Cluster.ClusterDNS.dnsDetails')}
								<IconButton disabled  style={{padding : "5px"}}>
									{props?.dnsId > 0 &&
									<CheckCircleSharpIcon
									fontSize="small"
									style={{ color: "green" }}
									/>
									}
								</IconButton>	
						</Typography>}
					subheader ={
						<Typography display="block" className="m-b-5" variant="body2"> {t('Cluster.ClusterDNS.dnsRequired')}{" "} </Typography>
					}
					// avatar={
					// 	<IconButton
					// 		className={clsx(classes.expand, {
					// 			[classes.expandOpen]: expanded,
					// 	})}
					// 	onClick={() => {
					// 		setExpanded(!expanded);
					// 	}}
					// 	>
					// 		<ExpandMoreIcon />
					// 	</IconButton>
					// }
					action={
						<>
							{(props.destroyed || !props.dnsId || props.dnsId <= 0 || props.packagesInstalled) ? (
								<></> 
							) : (
								expanded &&
								<Tooltip className={classes.dnsEditIcon} title={editDns ? "Cancel edit": "Edit"} placement="left" data-test="tool-tip-component">
									<IconButton
										onClick={handleEditIconClick}
										className={editDns ? classes.editIcon : ""}
									>
										<EditIcon />
									</IconButton>
								</Tooltip>
							)}
							<IconButton
								className={clsx(classes.expand, {
									[classes.expandOpen]: expanded,
								})}
								onClick={() => {
									setExpanded(!expanded);
								}}
								style={{alignItems: 'center'}}
							>
								<ExpandMoreIcon />
							</IconButton>
						</>
					}
				/>
				<Collapse in={expanded} data-test="card-content">
					{(!props.packagesInstalled && !props.destroyed) && (!props.dnsId || props.dnsId <= 0 || editDns) ? (
						<CardContent data-test="not-applied-card-content">
							<Grid
								container
								spacing={2}
								alignItems="center"
								justify="flex-start"
							>
								<Grid item md={8} xs={12}>
									<FormControl
										variant="outlined"
										fullWidth
										margin="normal"
									>
										<Select
											data-test="dns-change"
											color="primary"
											value={selectedDns ?? 0}
											onChange={(e) => handleDnsChange(e)}
											MenuProps={{
												getContentAnchorEl: null,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
											}}
											disabled={props.packagesInstalled}
										>
											<MenuItem value={0}>
												Select DNS
											</MenuItem>
											{props.dnsList &&
												props.dnsList.length > 0 &&
												props.dnsList.map((_dns: DNSModel, index: number) => (
													<MenuItem
														value={_dns.id}
														key={index}
													>
														{_dns.name}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={2} xs={6}>
									<Button
										data-test="create-button"
										onClick={handleCreateClick}
										color="primary"
										variant="contained"
										size="large"
										className="w-100"
										// disabled={isSetpValid()}
									>
										{t('Cluster.ClusterDNS.addDns')}
									</Button>
								</Grid>
								<Grid item xs={6} md={2}>
									<Button
										data-test="apply-button"
										onClick={handleSetupClick}
										color="primary"
										variant="contained"
										disabled={isSetupValid()}
										size="large"
										className="w-100"
									>
										{t('Cluster.ClusterDNS.apply')}
									</Button>							
								</Grid>
							</Grid>
						</CardContent>
					) : (
						<CardContent data-test="applied-card-content">
							{appliedDns ? (
								<div style={{display: 'flex', flexDirection: 'column', gap: 12}} data-test="applied-dns">
									<KeyValueRow keyXs={3} rowKey={t('Cluster.ClusterDNS.name')} rowValue={appliedDns?.name  ?? ""} />
									<KeyValueRow keyXs={3} rowKey={t('Cluster.ClusterInfo.created')} rowValue={ appliedDns.createdat ? getDateInStandardFormat(appliedDns.createdat) : ""} />
									<KeyValueRow keyXs={3} rowKey={t("Cluster.ClusterDNS.provider")} rowValue={appliedDns?.provider  ?? ""} />
									{appliedDns.region && (
										<KeyValueRow keyXs={3} rowKey={t('Cluster.ClusterDNS.region')} rowValue={appliedDns?.region  ?? ""} />
									)}
									<KeyValueRow keyXs={3} rowKey={t('Cluster.ClusterDNS.baseDomain')} rowValue={appliedDns?.base_domain  ?? ""} />
								</div>
							) : (
								<Grid data-test="no-dns">
									<div className="cluster-info-message">
										<ErrorOutlineIcon fontSize="small" />
										<Typography className="message" variant="caption">
											{t('Cluster.ClusterDNS.noDNSDetails')}
										</Typography>
									</div>
								</Grid>
							)}
						</CardContent>
					)}
				</Collapse>
			</Card>
			<ConfirmActionPopup
				data-test="action-popup-component"
				open={updateDnsPopup}
				message={t('Cluster.ClusterDNS.updateConfirm')}
				handleAgree={updateDnsAgreeHandler}
				handleDisAgree={updateDnsDisagreeHandler}
				loading={props.updatingDNS}
				yesText={t('Cluster.ClusterDNS.update')}
			/>
			{openPopup && (
				<AddDNSPopup
					data-test="add-dns-popup"
					openPopup={openPopup}
					handleClosePopup={handleClosePopup}
				/>
			)}
		</div>
	);
};

/*istanbul ignore next */
const mapStateToProps = (state: any) => ({
	updatingDNS: state.ClusterReducer.updatingDNS,
	dnsList: state.OrganizationReducer.dnsList,
});

/*istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
	return {
		getDns: () => dispatch(getDns()),
		clearDnsInfo: () => dispatch(clearDnsInfo()),
		setupClusterDNS: (id: number, jsonBody: any, mainClusterId: number, callback: () => void) =>
			dispatch(setupClusterDNS(id, jsonBody, mainClusterId, callback)),
	};
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ClusterDNS);
